import styled from 'styled-components';
import { Input } from './text';
import { getMarginBottom } from './text/themeGetters';
import { hummGray } from '../utils/colors';

const Container = styled.div`
  margin-bottom: ${(props) => getMarginBottom(props)};
`;

const StyledInput = styled(Input)`
  width: 100%;
  padding: 6px 0 6px 0;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  &:focus::placeholder {
    color: ${(props) => (props.hidePlaceholder ? 'transparent' : { hummGray })};
  }
`;

const Underline = styled.div`
  height: 2px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.lightGray : props.theme.colors.dark};
`;

function HummInput({
  marginBottom,
  children,
  onChange,
  disabled,
  defaultValue,
  centered = false,
  hidePlaceholder = false,
  showUnderline = true,
  className,
}) {
  return (
    <Container className={className} marginBottom={marginBottom}>
      <label>
        <StyledInput
          placeholder={children}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          centered={centered}
          hidePlaceholder={hidePlaceholder}
        />
      </label>
      {showUnderline && <Underline disabled={disabled} />}
    </Container>
  );
}

export default HummInput;
