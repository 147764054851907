import styled, { keyframes } from 'styled-components';
import logoDark from '../assets/logoDark.png';

const pulse = keyframes`
    0% { height: 40px }
    50% { height: 50px }
    100% { height: 40px }
`;

const AnimatedLogo = styled.img`
  height: 40px;
  animation: ${pulse} 0.75s linear infinite;
`;

function LoadingIcon() {
  return <AnimatedLogo src={logoDark} />;
}

export default LoadingIcon;
