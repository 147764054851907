import styled from 'styled-components';
import Section from '../../containers/Section';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { hummLightGreen } from '../../utils/colors';
import { Heading, Subheading, Paragraph } from '../text';
import { HummLinkButton } from '../buttons';
import { quiz } from '../../utils/routes';
import * as strings from '../../utils/strings';
import QuestionnaireIcon from '../../assets/QuestionnaireIcon-2X.png';
import CalendarIcon from '../../assets/CalendarIcon-2X.png';
import MoneyIcon from '../../assets/MoneyIcon-2X.png';

const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: ${(props) => props.theme.layout.margin.medium};
`;

const StepContainer = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabletAndMobileStepsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.theme.layout.margin.small};
`;

const TabletAndMobileStepContainer = styled.div`
  /* width: 33%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.theme.layout.margin.medium};
`;

const StepImage = styled.img`
  width: 80px;
  object-fit: contain;
  margin-bottom: ${(props) => props.theme.layout.margin.small};
`;

function HowItWorks({ height, backgroundOn }) {
  return (
    <Section
      height={height}
      colorType='light'
      titleColor={hummLightGreen}
      id='howItWorks'
      backgroundOn={backgroundOn}
    >
      <Heading colorType='primary'>{strings.howItWorksHeader}</Heading>
      <Subheading>{strings.howItWorksSubeader}</Subheading>
      <Paragraph>{strings.howItWorksFirstParagraph}</Paragraph>
      <Paragraph>{strings.howItWorksSecondParagraph}</Paragraph>
      <Paragraph>{strings.howItWorksThirdParagraph}</Paragraph>

      <Desktop>
        <StepsContainer>
          <StepContainer>
            <StepImage src={QuestionnaireIcon} />
            {/* <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep1}
            </Paragraph> */}
            <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep1Desc}
            </Paragraph>
          </StepContainer>
          <StepContainer>
            <StepImage src={CalendarIcon} />
            {/* <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep2}
            </Paragraph> */}
            <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep2Desc}
            </Paragraph>
          </StepContainer>
          <StepContainer>
            <StepImage src={MoneyIcon} />
            {/* <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep3}
            </Paragraph> */}
            <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep3Desc}
            </Paragraph>
          </StepContainer>
        </StepsContainer>
      </Desktop>

      <TabletAndMobile>
        <TabletAndMobileStepsContainer>
          <TabletAndMobileStepContainer>
            <StepImage src={QuestionnaireIcon} />
            {/* <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep1}
            </Paragraph> */}
            <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep1Desc}
            </Paragraph>
          </TabletAndMobileStepContainer>
          <TabletAndMobileStepContainer>
            <StepImage src={CalendarIcon} />
            {/* <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep2}
            </Paragraph> */}
            <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep2Desc}
            </Paragraph>
          </TabletAndMobileStepContainer>
          <TabletAndMobileStepContainer>
            <StepImage src={MoneyIcon} />
            {/* <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep3}
            </Paragraph> */}
            <Paragraph marginBottom='none' fontWeight='bold'>
              {strings.howItWorksStep3Desc}
            </Paragraph>
          </TabletAndMobileStepContainer>
        </TabletAndMobileStepsContainer>
      </TabletAndMobile>

      <HummLinkButton to={quiz} colorType='light'>
        {strings.estimateSavings}
      </HummLinkButton>
    </Section>
  );
}

export default HowItWorks;
