import { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import validator from 'email-validator';
import { uploadBlogLead } from '../../controllers/leadController';
import { Subheading, Paragraph } from '../text';
import HummInput from '../HummInput';
import HummButton from '../buttons/HummButton';
import * as strings from '../../utils/strings';
import { hummDarkBlue } from '../../utils/colors';

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const StyledHummInput = styled(HummInput)`
  width: 100%;
  text-align: center;
`;

const StyledHummButton = styled(HummButton)`
  margin-top: 20px;
  align-self: center;
`;

const ResponsiveContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 1px ${hummDarkBlue};
  margin-bottom: 20px;
`;

const ResponsiveInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 5px;
`;

const ResponsiveHummInput = styled(HummInput)`
  width: 100%;
  background-color: transparent;
  text-align: center;
`;

const ResponsiveHummButton = styled(HummButton)`
  height: 30px;
  padding: 3px 18px 3px 18px;
  border-radius: 3px;
  margin-top: 0px;
`;

function BlogCTA() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);
  const location = useLocation();

  const handleEmailChange = (event) => {
    setError('');
    setEmail(event.target.value);
  };

  const handleClick = () => {
    const isValidEmail = validator.validate(email);
    if (isValidEmail) {
      console.log(`Path: ${location.pathname}`);
      uploadBlogLead(email, location.pathname);
      setComplete(true);
      setTimeout(() => {
        setComplete(false);
      }, 2000);
    } else {
      setError(strings.errorInvalidEmail);
    }
  };

  return (
    <div>
      <Desktop>
        <Container>
          {complete && (
            <div>
              <Subheading colorType='primary' fontSize='xlarge' marginBottom='small'>
                Thanks!
              </Subheading>
              <Paragraph colorType='dark'>We'll be in touch</Paragraph>
            </div>
          )}
          {!complete && (
            <Container>
              <Paragraph>{strings.blogCTAText}</Paragraph>
              <StyledHummInput onChange={handleEmailChange} centered={true} hidePlaceholder={true}>
                {strings.quizEmailPlaceholder}
              </StyledHummInput>
              {error !== '' && (
                <Paragraph marginBottom='none' colorType='error'>
                  {error}
                </Paragraph>
              )}
              <StyledHummButton onClick={handleClick}>{strings.buttonSubmit}</StyledHummButton>
            </Container>
          )}
        </Container>
      </Desktop>
      <TabletAndMobile>
        <ResponsiveContainer>
          {!complete && (
            <Container>
              <Paragraph fontSize='xsmall' marginBottom='none'>
                {strings.blogCTATextShort}
              </Paragraph>
              <ResponsiveInputContainer>
                <ResponsiveHummInput onChange={handleEmailChange} showUnderline={false}>
                  {strings.quizEmailPlaceholder}
                </ResponsiveHummInput>

                <ResponsiveHummButton onClick={handleClick}>
                  {strings.buttonSubmit}
                </ResponsiveHummButton>
              </ResponsiveInputContainer>
              {error !== '' && (
                <Paragraph marginBottom='none' colorType='error'>
                  {error}
                </Paragraph>
              )}
            </Container>
          )}
        </ResponsiveContainer>
      </TabletAndMobile>
    </div>
  );
}

export default BlogCTA;
