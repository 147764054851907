import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // color: state.isSelected ? 'red' : 'blue',
    // padding: 20,
  }),
};

function HummSelect({ options, onChange, defaultValue }) {
  return (
    <Select
      isSearchable={false}
      styles={customStyles}
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      theme={(theme) => ({
        ...theme,
        borderRadius: 6,
        colors: {
          ...theme.colors,
          primary25: '#f2f2f2',
          primary: '#61BE75',
        },
      })}
    />
  );
}

export default HummSelect;
