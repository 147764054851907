import * as colors from './colors';

export const hummStandard = {
  colors: {
    primary: colors.hummLightGreen,
    light: colors.hummWhite,
    dark: colors.hummDarkBlue,
    lightGray: colors.hummLightGray,
  },
  constants: {
    scrollDuration: 500,
  },
  text: {
    color: {
      light: colors.hummWhite,
      dark: colors.hummDarkBlue,
      primary: colors.hummLightGreen,
      highlight: colors.hummNeonYellow,
      faded: colors.hummGray,
      error: colors.hummRed,
    },
    size: {
      xxlarge: '3.75rem', // 60pt
      xlarge: '2.75rem', // 36pt
      large: '1.375rem', // 22pt
      medium: '1.125rem', // 18pt
      small: '1rem', // 16pt
      xsmall: '0.875rem', // 14pt
      xxsmall: '0.625rem', // 10pt
    },
    font: {
      title: 'lora, serif',
      body: 'acumin-pro, sans-serif',
    },
    weight: {
      regular: 400,
      bold: 600,
    },
  },
  layout: {
    margin: {
      xxlarge: '120px',
      xlarge: '102px',
      large: '60px',
      medium: '42px',
      small: '18px',
      xsmall: '12px',
      xxsmall: '6px',
    },
    padding: {
      xlarge: '',
      large: '',
      medium: '',
      small: '',
      xsmall: '6px',
    },
  },
};
