import { uploadLead } from '../services/airtableService';
import { calculateHummSavings } from '../utils/taxMaths/savingsCalculator';

export const uploadYearEndLead = (email) => {
  uploadLead(email, 'yearEndChecklist');
};

export const uploadBlogLead = (email, path) => {
  const data = { path };
  uploadLead(email, 'blog', data);
};

export const uploadSavingsCalculatorLead = (email, income, expenses, savings) => {
  const data = { income, expenses, savings };
  uploadLead(email, 'savingsCalculator', data);
};

export const calculateSavings = (income, expenses) => {
  let savings = calculateHummSavings(income, expenses);
  savings = savings < 0 ? 0 : savings;
  return savings;
};
