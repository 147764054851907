import styled from 'styled-components';
import Section from '../../containers/Section';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { Heading, Subheading, Paragraph } from '../text';
import HummInfoTable from '../HummInfoTable';
import HummBulletedList from '../HummBulletedList';
import { HummLinkButton } from '../buttons';
import { quiz } from '../../utils/routes';
import { hummLightGreen, hummWhite, hummDarkBlue } from '../../utils/colors';
import * as strings from '../../utils/strings';

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 40px 0 40px 0;
`;

const ResponsiveTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;
`;

const BulletContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: left;
`;

const ParagraphContainer = styled.div`
  width: 50%;
`;

const StyledBulletedList = styled(HummBulletedList)`
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  border-width: 1px;
  border-style: solid;
  border-color: ${hummDarkBlue};
  border-radius: 6px;
  box-shadow: 0px 0px 4px 1px ${hummDarkBlue};
`;

const ResponsiveTableContainer = styled.div``;

const StyledHummInfoTable = styled(HummInfoTable)`
  width: 800px;
  flex: 0 0 auto;
  box-shadow: none;
`;

function UnderTheHood({ height, backgroundOn }) {
  return (
    <Section colorType='light' id='underTheHood' backgroundOn={backgroundOn}>
      <Heading colorType='primary' marginBottom='medium'>
        {strings.underTheHoodHeader}
      </Heading>
      <Desktop>
        <HummInfoTable
          data={strings.underTheHoodTableData}
          hasRowHeaders={true}
          highlightColumnIndex={5}
          highlightColor={hummLightGreen}
          highlightTextColor={hummWhite}
        />
        <TextContainer>
          <BulletContainer>
            <Subheading textAlign='left' colorType='dark' marginBottom='small'>
              {strings.underTheHoodSubheader}
            </Subheading>
            <HummBulletedList bullets={strings.underTheHoodList} colorType='dark' />
          </BulletContainer>
          <ParagraphContainer>
            <Paragraph colorType='dark' marginBottom='xxxsmall' fontSize='medium'>
              {strings.underTheHoodRemember}
            </Paragraph>
          </ParagraphContainer>
        </TextContainer>
      </Desktop>
      <TabletAndMobile>
        <Wrapper>
          <ResponsiveTableContainer>
            <StyledHummInfoTable
              data={strings.underTheHoodTableData}
              hasRowHeaders={true}
              highlightColumnIndex={5}
              highlightColor={hummLightGreen}
              highlightTextColor={hummWhite}
            />
          </ResponsiveTableContainer>
        </Wrapper>
        <ResponsiveTextContainer>
          <Subheading textAlign='left' colorType='dark' marginBottom='small'>
            {strings.underTheHoodSubheader}
          </Subheading>
          <StyledBulletedList bullets={strings.underTheHoodList} colorType='dark' />
          <Paragraph colorType='dark' marginBottom='xxxsmall' fontSize='medium'>
            {strings.underTheHoodRemember}
          </Paragraph>
        </ResponsiveTextContainer>
      </TabletAndMobile>
      <HummLinkButton to={quiz}>{strings.getStartedNow}</HummLinkButton>
    </Section>
  );
}

export default UnderTheHood;
