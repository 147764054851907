import styled from 'styled-components';
import {
  getFontFamily,
  getFontSize,
  getFontWeight,
  getFontColor,
  getMarginBottom,
} from './themeGetters';

const StyledInput = styled.input`
  text-align: left;
  font-family: ${(props) => getFontFamily(props)};
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => getFontWeight(props)};
  color: ${(props) => getFontColor(props)};
  padding: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: ${(props) => getMarginBottom(props)};
  margin-left: 0;
  border: 0;
  &:focus {
    outline: 0;
  }
  &:disabled {
    opacity: 20%;
    background: transparent;
  }
`;

function Input({
  className,
  font = 'body',
  fontSize = 'small',
  fontWeight = 'bold',
  colorType = 'dark',
  marginBottom = 'none',
  type = 'text',
  placeholder,
  defaultValue,
  disabled,
  children,
  onChange,
}) {
  return (
    <StyledInput
      className={className}
      font={font}
      fontSize={fontSize}
      fontWeight={fontWeight}
      colorType={colorType}
      marginBottom={marginBottom}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue === '' ? null : defaultValue}
      disabled={disabled}
      onChange={onChange}
    >
      {children}
    </StyledInput>
  );
}

export default Input;
