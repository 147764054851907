import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { hummDarkBlue, hummGray } from '../../utils/colors';
import { Subheading, Subtext, Paragraph } from '../text';
import { formatDate } from '../../utils/dates';
import * as strings from '../../utils/strings';

const Card = styled(Link)`
  text-decoration: none;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DateText = styled(Subtext)`
  color: ${hummGray};
`;

const Title = styled(Subheading)`
  font-size: 2rem;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const PreviewText = styled(ReactMarkdown)`
  color: ${hummDarkBlue};
`;

const ReadMore = styled(Paragraph)``;

const Divider = styled.div`
  height: 1px;
  background-color: ${hummDarkBlue};
  opacity: 50%;
  margin: 20px 0 40px 0;
`;

function PostCard({ post }) {
  return (
    <Card to={`/blog/post/${post.slug}`}>
      <Content>
        <DateText>{formatDate(post.date)}</DateText>
        <Title textAlign='left' fontSize='large' marginBottom='xsmall'>
          {post.title}
        </Title>
        <Image src={post.thumbnailUrl} />
        <PreviewText>{post.previewText}</PreviewText>
        <ReadMore colorType='primary' marginBottom='xxsmall'>
          {strings.blogReadMore}
        </ReadMore>
      </Content>
      <Divider />
    </Card>
  );
}

export default PostCard;
