import HummButton from './HummButton';
import { openPopupWidget } from 'react-calendly';
import { calendlyUrl } from '../../utils/constants';

function CalendlyButton({ children }) {
  const handleOnClick = () => {
    const url = calendlyUrl;
    const prefill = {};
    const pageSettings = {};
    const utm = {};

    openPopupWidget({ url, prefill, pageSettings, utm });
  };
  return <HummButton onClick={handleOnClick}>{children}</HummButton>;
}

export default CalendlyButton;
