import styled from 'styled-components';
import { Subheading } from './text';
const format = require('format-number');

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
  justify-content: center;
`;

function HummDollarValue({ title, value }) {
  return (
    <TextContainer>
      <Subheading marginBottom='xsmall'>{title}: </Subheading>
      <Subheading marginBottom='xsmall' colorType='primary'>
        {format({ prefix: '$', truncate: 0 })(value)}
      </Subheading>
    </TextContainer>
  );
}

export default HummDollarValue;
