import styled from 'styled-components';
import top from '../../assets/TOP.png';

const StyledTopButton = styled.img`
  position: fixed;
  width: 50px;
  bottom: 0;
  /* left: 0; */
  padding: 15px;
  cursor: pointer;
  &:hover {
    opacity: 60%;
  }
`;

function TopButton({ onClick }) {
  return <StyledTopButton src={top} onClick={onClick} />;
}

export default TopButton;
