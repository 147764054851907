const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate() + 1;

  if (day < 10) {
    day = '0' + day;
  }

  const formattedDate = `${monthNames[month]} ${day}, ${year}`;
  return formattedDate;
}
