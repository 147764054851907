import { useState } from 'react';
import styled from 'styled-components';
import { QuizLayout, QuizSubheading } from './QuizLayout';
import HummInput from '../HummInput';
import HummSelect from '../HummSelect';
import * as strings from '../../utils/strings';

const Spacer = styled.div`
  height: ${(props) => props.theme.layout.margin.small};
`;

const getDefaultValue = (id, responses, options) => {
  if (responses[id]) {
    return options[responses[`${id}Metadata`].optionIndex];
  } else {
    return '';
  }
};

const getUserOption = (id, responses, options) => {
  if (responses[id] && responses[`${id}Metadata`].optionIndex === options.length - 1) {
    return responses[`${id}Metadata`].userOption;
  } else {
    return '';
  }
};

function QuizRole({ id, stepNum, totalSteps, onChange, onSubmit, onBack, responses }) {
  const [option, setOption] = useState(getDefaultValue(id, responses, strings.industryOptions));
  const [userOption, setUserOption] = useState(
    getUserOption(id, responses, strings.industryOptions)
  );

  const handleChange = (selectedOption) => {
    setOption(selectedOption);
    onChange(id, {
      target: {
        value: selectedOption.value,
        metadata: { optionIndex: strings.industryOptions.indexOf(selectedOption) },
      },
    });
  };

  const handleInputChange = (event) => {
    setUserOption(event.target.value);
    onChange(id, {
      target: {
        value: option.value,
        metadata: {
          optionIndex: strings.industryOptions.indexOf(option),
          userOption: event.target.value,
        },
      },
    });
  };

  const isDisabled = () => {
    if (option.value !== '' && option.value !== 'other') return false;
    if (option.value === 'other' && userOption !== '') return false;
    return true;
  };

  return (
    <QuizLayout
      stepNum={stepNum}
      totalSteps={totalSteps}
      heading={strings.quiz2Heading}
      buttonText={strings.buttonNext}
      onSubmit={onSubmit}
      onBack={onBack}
      disabled={isDisabled()}
    >
      <QuizSubheading marginBottom='xlarge'>{strings.quiz2Question}</QuizSubheading>
      <HummSelect options={strings.industryOptions} onChange={handleChange} defaultValue={option} />
      <Spacer />
      <HummInput
        disabled={option.value !== 'other'}
        id={id}
        onChange={handleInputChange}
        defaultValue={userOption}
      >
        {strings.quiz2Placeholder}
      </HummInput>
    </QuizLayout>
  );
}

export default QuizRole;
