import Metrics from './metrics';

class Applicant {
  constructor() {
    this.email = '';
    this.location = '';
    this.industry = '';
    this.taxHistory = '';
    this.isEligible = false;
    this.metrics = [Metrics];
  }

  setEmail(email) {
    this.email = email;
  }

  setLocation(location) {
    this.location = location;
  }

  setIndustry(industry) {
    this.industry = industry;
  }

  setTaxHistory(taxHistory) {
    this.taxHistory = taxHistory;
  }

  setIsEligible(isEligible) {
    this.isEligible = isEligible;
  }

  addMetrics(year, income, expenses) {
    const metrics = new Metrics(year, income, expenses);
    this.metrics.push(metrics);
  }

  getIsEligible() {
    return this.isEligible;
  }

  getFields() {
    return JSON.stringify(this);
  }
}

export default Applicant;
