import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const IsDesktop = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const TabletAndMobile = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 991 });
  return isTabletOrMobile ? children : null;
};

export { Desktop, IsDesktop, Tablet, Mobile, TabletAndMobile };
