const Airtable = require('airtable');
const quizBase = new Airtable({ apiKey: 'keyVV9YcOLJ306Htz' }).base('apprErusqqvH7W2PD');
const leadBase = new Airtable({ apiKey: 'keyVV9YcOLJ306Htz' }).base('appCir0uXiZE1FxZz');
const blogBase = new Airtable({ apiKey: 'keyVV9YcOLJ306Htz' }).base('appkPCB9Zi3KV0rd1');

const eligibleTable = 'eligible';
const ineligibleTable = 'ineligible';
const leadsTable = 'leads';
const blogPostsTable = 'blogPosts';

exports.uploadApplicant = (applicant) => {
  const table = applicant.getIsEligible() ? eligibleTable : ineligibleTable;
  quizBase(table).create(
    [
      {
        fields: {
          email: applicant.email,
          location: applicant.location,
          industry: applicant.industry,
          taxHistory: applicant.taxHistory,
          businessMetrics: JSON.stringify(applicant.metrics),
        },
      },
    ],
    function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
    }
  );
};

exports.uploadLead = (email, campaign, data) => {
  leadBase(leadsTable).create(
    [
      {
        fields: {
          email: email,
          campaign: campaign,
          data: JSON.stringify(data),
        },
      },
    ],
    function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
    }
  );
};

exports.fetchPostList = () => {
  return new Promise((resolve, reject) => {
    const results = [];
    blogBase(blogPostsTable)
      .select({
        fields: ['title', 'slug', 'date', 'headerImage', 'previewText'],
        sort: [{ field: 'date', direction: 'desc' }],
        filterByFormula: `({status} = 'published')`,
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach((record) => {
            console.log(
              `LOG: ${JSON.stringify(record.get('headerImage')[0].thumbnails.small.url)}`
            );
            results.push({
              title: record.get('title'),
              slug: record.get('slug'),
              date: record.get('date'),
              thumbnailUrl: record.get('headerImage')[0].thumbnails.large.url,
              previewText: record.get('previewText').concat('...'),
            });
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return reject(err);
          }
          resolve(results);
        }
      );
  });
};

exports.fetchPost = (postSlug) => {
  return new Promise((resolve, reject) => {
    blogBase(blogPostsTable)
      .select({
        filterByFormula: `({slug} = '${postSlug}')`,
        maxRecords: 1,
      })
      .eachPage(
        function page(records) {
          resolve(records[0]._rawJson.fields);
        },
        function done(err) {
          if (err) {
            console.error(err);
            return reject(err);
          }
        }
      );
  });
};
