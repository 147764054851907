import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { hummLightGreen } from '../../utils/colors';

const useStyles = makeStyles({
  container: {
    marginBottom: 42,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    borderColor: '#3cab26',
    borderWidth: 2,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #61BE75',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
  },
  checkedIcon: {
    backgroundColor: '#ffffff',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#61BE75,#61BE75 35%,transparent 40%)',
      content: '""',
    },
  },
});

const StyledFormControl = (props) => {
  const classes = useStyles();

  return <FormControl className={classes.container} {...props} />;
};

const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      color={hummLightGreen}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const RadioButtonGroup = ({ options, onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : '');

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <StyledFormControl component='fieldset'>
      <RadioGroup name='taxInfo' value={value} onChange={handleRadioChange}>
        {options &&
          options.map((option) => (
            <FormControlLabel value={option} control={<StyledRadio />} label={option} />
          ))}
      </RadioGroup>
    </StyledFormControl>
  );
};

export default RadioButtonGroup;
