import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Desktop, TabletAndMobile } from '../containers/Breakpoints';
import { Subheading, Paragraph } from './text';
import chevronDownDark from '../assets/chevronDownDark.png';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const Container = styled.div`
  width: 100%;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.dark};
`;

const StyledChevron = styled.img`
  width: 12px;
  object-fit: contain;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const StyledSubheader = styled(Subheading)`
  text-align: left;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: left;
`;

const StyledAccordion = styled(Accordion)`
  margin: auto;
`;

const TabletAndMobileStyledAccordion = styled(Accordion)``;

const StyledAccordionItem = styled(AccordionItem)``;

const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  padding-right: 36px;
`;

const StyledAccordionItemButton = styled(AccordionItemButton)`
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }

  &:focus {
    outline: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  padding: 0 20px 20px 20px;
  animation: ${fadeIn} 0.35s ease-in;
`;

const getInnards = (items, openSection) => {
  return (
    <Container>
      <HorizontalDivider />
      {items.map((item) => (
        <StyledAccordionItem uuid={item.id}>
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <StyledSubheader
                colorType='light'
                fontSize='small'
                fontWeight='bold'
                marginBottom='none'
              >
                {item.heading}
              </StyledSubheader>
              <StyledChevron src={chevronDownDark} isOpen={openSection == item.id} />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <StyledParagraph colorType='light' fontSize='medium' marginBottom='none'>
              {item.content}
            </StyledParagraph>
          </StyledAccordionItemPanel>
          <HorizontalDivider />
        </StyledAccordionItem>
      ))}
    </Container>
  );
};

function HummAccordion({ items }) {
  const [openSection, setOpenSection] = useState('');

  const handleChange = (uuid) => {
    setOpenSection(uuid);
  };

  return (
    <Container>
      <Desktop>
        <StyledAccordion allowZeroExpanded={true} onChange={handleChange}>
          {getInnards(items, openSection)}
        </StyledAccordion>
      </Desktop>
      <TabletAndMobile>
        <TabletAndMobileStyledAccordion allowZeroExpanded={true}>
          {getInnards(items, openSection)}
        </TabletAndMobileStyledAccordion>
      </TabletAndMobile>
    </Container>
  );
}

export default HummAccordion;
