import Section from '../../containers/Section';
import { Heading, Subheading, Paragraph } from '../text';
import { HummLinkButton } from '../buttons';
import { quiz } from '../../utils/routes';
import * as strings from '../../utils/strings';

function WhatWeDo({ height, backgroundOn }) {
  return (
    <Section height={height} colorType='primary' id='whatWeDo' backgroundOn={backgroundOn}>
      <Heading colorType='dark'>{strings.whatWeDoHeader}</Heading>
      <Subheading colorType='light'>{strings.whatWeDoSubheader}</Subheading>
      <Paragraph colorType='light'>{strings.whatWeDoFirstParagraph}</Paragraph>
      <Paragraph colorType='light'>{strings.whatWeDoSecondParagraph}</Paragraph>
      <Paragraph colorType='light'>{strings.whatWeDoThirdParagraph}</Paragraph>
      <HummLinkButton to={quiz}>{strings.getStartedNow}</HummLinkButton>
    </Section>
  );
}

export default WhatWeDo;
