import styled from 'styled-components';
import { Desktop, TabletAndMobile } from './Breakpoints';

const paddingTop = 100;
const paddingBottom = 50;

const getAdjustedHeight = (props) => {
  return props.height - (paddingTop + paddingBottom);
};

const getBackgroundColor = (props) => {
  if (props.backgroundOn) {
    switch (props.colorType) {
      case 'light':
        return props.theme.text.color.light;
      case 'dark':
        return props.theme.text.color.dark;
      case 'faded':
        return props.theme.text.color.faded;
      case 'primary':
        return props.theme.text.color.primary;
      default:
        return props.theme.text.color.dark;
    }
  } else {
    return 'transparent';
  }
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => getAdjustedHeight(props) + 'px'};
  padding: 100px 75px 50px 75px;
  background-color: ${(props) => getBackgroundColor(props)};
`;

const TabletAndMobileSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => getAdjustedHeight(props) + 'px'};
  padding: 100px 40px 50px 40px;
  background-color: ${(props) => getBackgroundColor(props)};
`;

const SectionTitle = styled.h1`
  color: ${(props) => props.color};
`;

function Section({ title, height, colorType, titleColor, id, children, onMeasure, backgroundOn }) {
  return (
    <div>
      <Desktop>
        <SectionContainer
          height={height}
          colorType={colorType}
          id={id}
          onMeasure={onMeasure}
          backgroundOn={backgroundOn}
        >
          {title && <SectionTitle color={titleColor}>{title}</SectionTitle>}
          {children}
        </SectionContainer>
      </Desktop>
      <TabletAndMobile>
        <TabletAndMobileSectionContainer
          height={height}
          colorType={colorType}
          id={id}
          onMeasure={onMeasure}
          backgroundOn={backgroundOn}
        >
          {title && <SectionTitle color={titleColor}>{title}</SectionTitle>}
          {children}
        </TabletAndMobileSectionContainer>
      </TabletAndMobile>
    </div>
  );
}

export default Section;
