export const getFontFamily = (props) => {
  switch (props.font) {
    case 'title':
      return props.theme.text.font.title;
    case 'body':
      return props.theme.text.font.body;
    default:
      return props.theme.text.font.body;
  }
};

export const getFontSize = (props) => {
  switch (props.fontSize) {
    case 'xxlarge':
      return props.theme.text.size.xxlarge;
    case 'xlarge':
      return props.theme.text.size.xlarge;
    case 'large':
      return props.theme.text.size.large;
    case 'medium':
      return props.theme.text.size.medium;
    case 'small':
      return props.theme.text.size.small;
    case 'xsmall':
      return props.theme.text.size.xsmall;
    case 'xxsmall':
      return props.theme.text.size.xxsmall;
    default:
      return props.theme.text.size.large;
  }
};

export const getFontWeight = (props) => {
  switch (props.fontWeight) {
    case 'regular':
      return props.theme.text.weight.regular;
    case 'bold':
      return props.theme.text.weight.bold;
    default:
      return props.theme.text.weight.regular;
  }
};

export const getFontColor = (props) => {
  switch (props.colorType) {
    case 'light':
      return props.theme.text.color.light;
    case 'dark':
      return props.theme.text.color.dark;
    case 'faded':
      return props.theme.text.color.faded;
    case 'primary':
      return props.theme.text.color.primary;
    case 'error':
      return props.theme.text.color.error;
    default:
      return props.theme.text.color.dark;
  }
};

export const getMarginBottom = (props) => {
  switch (props.marginBottom) {
    case 'xxlarge':
      return props.theme.layout.margin.xxlarge;
    case 'xlarge':
      return props.theme.layout.margin.xlarge;
    case 'large':
      return props.theme.layout.margin.large;
    case 'medium':
      return props.theme.layout.margin.medium;
    case 'small':
      return props.theme.layout.margin.small;
    case 'xsmall':
      return props.theme.layout.margin.xsmall;
    case 'xxsmall':
      return props.theme.layout.margin.xxsmall;
    case 'none':
      return 0;
    default:
      return 0;
  }
};
