import styled from 'styled-components';
import circleArrow from '../../assets/circleArrow.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  align-items: center;
  &:hover {
    opacity: 60%;
  }
`;

const Image = styled.img`
  width: 25px;
  object-fit: contain;
  transform: rotate(90deg);
`;

function ArrowButton({ onClick }) {
  return (
    <Container onClick={onClick}>
      <Image src={circleArrow} />
    </Container>
  );
}

export default ArrowButton;
