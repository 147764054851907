import { Desktop, IsDesktop, TabletAndMobile } from '../../containers/Breakpoints';
import { QuizHeading, QuizLayout, QuizSubheading } from './QuizLayout';
import * as strings from '../../utils/strings';

function QuizPrimer({ id, onSubmit }) {
  return (
    <QuizLayout
      heading={IsDesktop() ? strings.quizPrimerHeading : null}
      buttonText={strings.quizPrimerButton}
      onSubmit={onSubmit}
      showSteps={false}
      centered={true}
    >
      <TabletAndMobile>
        <QuizHeading centered={true} fontSize='large' fontWeight='bold' marginBottom='medium'>
          {strings.quizPrimerHeading}
        </QuizHeading>
      </TabletAndMobile>
      <QuizSubheading centered={true}>{strings.quizPrimerSubheading}</QuizSubheading>
      <QuizSubheading centered={true}>{strings.quizPrimerCTA}</QuizSubheading>
    </QuizLayout>
  );
}

export default QuizPrimer;
