import React from 'react';
import ReactDOM from 'react-dom';
import Homepage from './containers/Homepage';
import Quiz from './containers/Quiz';
import LeadForm from './containers/LeadForm';
import Scheduler from './containers/Scheduler';
// import Blog from './containers/Blog';
import PostList from './components/blog/PostList';
import Post from './components/blog/Post';
import PrivacyPolicy from './components/miscPages/PrivacyPolicy';
import { LeadThankYou, LeadSavingsCalculator } from './components/leadForm';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { hummStandard } from './utils/themes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';

import TagManager from 'react-gtm-module';
import firebase from 'firebase/app';
import 'firebase/storage';

const tagManagerArgs = {
  gtmId: 'GTM-57N6Q5G',
};

TagManager.initialize(tagManagerArgs);

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAQiCcErLp38PNVhbdcy5idy4BCSJ255IY',
  authDomain: 'humm-accounting.firebaseapp.com',
  projectId: 'humm-accounting',
  storageBucket: 'humm-accounting.appspot.com',
  messagingSenderId: '898337081629',
  appId: '1:898337081629:web:ee01278a172e122ab239f4',
  measurementId: 'G-WL9R41FBH4',
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={hummStandard}>
        <Switch>
          <Route exact path='/'>
            <Homepage />
          </Route>
          <Route path='/questionnaire'>
            <Quiz />
          </Route>
          <Route path='/yearEndChecklist'>
            <LeadForm />
          </Route>
          <Route path='/savingsCalculator'>
            <LeadSavingsCalculator />
          </Route>
          <Route path='/thankYou'>
            <LeadThankYou />
          </Route>
          <Route path='/schedule'>
            <Scheduler />
          </Route>
          <Route exact path='/blog'>
            <PostList />
          </Route>
          <Route path='/blog/post/:postSlug' component={Post} />
        </Switch>
        <Route exact path='/privacy'>
          <PrivacyPolicy />
        </Route>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
