import styled from 'styled-components';
import { QuizContainer, QuizHeading, QuizSubheading } from './QuizLayout';
import * as strings from '../../utils/strings';
import logo from '../../assets/HUMM-_Submark-Green2.png';

const Logo = styled.img`
  width: 40px;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function QuizThankYouIneligible({ onSubmit }) {
  onSubmit();
  return (
    <QuizContainer>
      <Container>
        <QuizHeading marginBottom='medium'>{strings.quizThankYouHeading}</QuizHeading>
        <QuizSubheading marginBottom='xlarge'>{strings.quizThankYouSubheading2}</QuizSubheading>
        <Logo src={logo} />
      </Container>
    </QuizContainer>
  );
}

export default QuizThankYouIneligible;
