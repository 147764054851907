import firebase from 'firebase/app';
import * as strings from '../utils/strings';

export const fetchYearEndUrl = async () => {
  const storage = firebase.app().storage();
  const storageRef = storage.ref();
  let url = '';
  try {
    url = await storageRef.child(strings.yearEndLeadFormStorageLocation).getDownloadURL();
  } catch (e) {}
  return url;

  //   storageRef
  //     .child('lead-forms/LeadMagnet-5asks.pdf')
  //     .getDownloadURL()
  //     .then((url) => {
  //       console.log(`GotURL: ${url}`);
  //       return url;
  //     })
  //     .catch((error) => {
  //       // A full list of error codes is available at
  //       // https://firebase.google.com/docs/storage/web/handle-errors
  //       console.log(`File Download Error: ${error.code}`);
  //       switch (error.code) {
  //         case 'storage/object-not-found':
  //           // File doesn't exist
  //           break;
  //         case 'storage/unauthorized':
  //           // User doesn't have permission to access the object
  //           break;
  //         case 'storage/canceled':
  //           // User canceled the upload
  //           break;
  //         case 'storage/unknown':
  //           // Unknown error occurred, inspect the server response
  //           break;
  //         default:
  //           break;
  //       }
  //     });
};
