import styled from 'styled-components';
import { hummDarkBlue, hummLightGreen } from '../utils/colors';
import { Link } from 'react-router-dom';
import logo from '../assets/HUMM-_PrimaryLogo-White2.png';

const Container = styled.div`
  background-color: ${hummLightGreen};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  height: 80px;
  margin: auto;
`;

const Logo = styled.img`
  height: 40px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${hummDarkBlue};
  opacity: 50%;
`;

function Header() {
  return (
    <Container>
      <Content>
        <Link to='/'>
          <Logo src={logo} />
        </Link>
      </Content>
      {/* <Divider /> */}
    </Container>
  );
}

export default Header;
