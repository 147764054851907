import styled from 'styled-components';

const Icon = styled.img`
  height: 15px;
  object-fit: contain;
  margin: 15px;
`;

function IconLink({ src, to }) {
  return (
    <a href={to} target='_blank' rel='noopener noreferrer' oncontextmenu='return false;'>
      <Icon src={src} />
    </a>
  );
}

export default IconLink;
