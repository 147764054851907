import { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { HummContentContainer, HummHeading, HummSubheading } from '../hummComponents/HummLayout';
import HummSlider from '../HummSlider';
import HummDollarValue from '../HummDollarValue';
import HummInput from '../HummInput';
import { HummButton } from '../buttons';
import { Paragraph } from '../text';
import { calculateSavings, uploadSavingsCalculatorLead } from '../../controllers/leadController';
import validator from 'email-validator';
import * as strings from '../../utils/strings';

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledHummButton = styled(HummButton)`
  margin-top: 20px;
`;

function LeadSavingsCalculator() {
  const [income, setIncome] = useState(150000);
  const [expenses, setExpenses] = useState(20000);
  const [savings, setSavings] = useState(calculateSavings(income, expenses));
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);

  const handleSliderChange = (id, value) => {
    if (id === 'income') {
      setIncome(value);
      setSavings(calculateSavings(value, expenses));
    } else if (id === 'expenses') {
      setExpenses(value);
      setSavings(calculateSavings(income, value));
    }
  };

  const handleEmailChange = (event) => {
    setError('');
    setEmail(event.target.value);
  };

  const handleClick = () => {
    const isValidEmail = validator.validate(email);
    if (isValidEmail) {
      uploadSavingsCalculatorLead(email, income, expenses, savings);
      setComplete(true);
    } else {
      setError(strings.errorInvalidEmail);
    }
  };

  return (
    <div>
      {complete && <Redirect to={`/thankYou`} />}
      <HummContentContainer>
        <HummHeading centered={true}>{strings.leadSavingsHeading}</HummHeading>
        <HummSubheading centered={true}>{strings.leadSavingsSubheading}</HummSubheading>
        <HummSlider
          id='income'
          title='Income'
          min={0}
          max={500000}
          defaultValue={income}
          onChange={handleSliderChange}
        />
        <HummSlider
          id='expenses'
          title='Expenses'
          min={0}
          max={500000}
          defaultValue={expenses}
          onChange={handleSliderChange}
        />
        <HummDollarValue title={strings.leadSavingsResult} value={savings} />
        <InputContainer>
          <HummInput onChange={handleEmailChange}>{strings.quizEmailPlaceholder2}</HummInput>
          {error !== '' && (
            <Paragraph marginBottom='none' colorType='error'>
              {error}
            </Paragraph>
          )}
          <ButtonContainer>
            <StyledHummButton onClick={handleClick}>{strings.buttonSubmit}</StyledHummButton>
          </ButtonContainer>
        </InputContainer>
      </HummContentContainer>
    </div>
  );
}

export default LeadSavingsCalculator;
