import styled from 'styled-components';
import { Paragraph, Subheading } from './text';
import arrowIcon from '../assets/arrowBullet.png';

const Container = styled.div``;

const Title = styled(Subheading)`
  text-align: left;
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const Icon = styled.img`
  width: 15px;
  object-fit: contain;
  margin: 6px 12px 0 0;
  filter: ${(props) => (props.colorType === 'light' ? 'invert(0%)' : 'invert(100%)')};
`;

const Text = styled(Paragraph)`
  text-align: left;
`;

const ArrowBullet = ({ children, colorType }) => {
  return (
    <Bullet>
      <Icon src={arrowIcon} colorType={colorType} />
      <Text colorType={colorType} marginBottom='none'>
        {children}
      </Text>
    </Bullet>
  );
};

function HummBulletedList({ className, title, bullets, colorType }) {
  return (
    <Container className={className}>
      {title && (
        <Title marginBottom='xxsmall' colorType={colorType}>
          {title}
        </Title>
      )}
      {bullets &&
        bullets.map((bullet) => <ArrowBullet colorType={colorType}>{bullet}</ArrowBullet>)}
    </Container>
  );
}

export default HummBulletedList;
