import { brackets } from './progressiveTaxBrackets';
import { round } from './math';

export const calculatePrePayrollProfit = (revenue, expenses) => {
  // =C6-C7
  return revenue - expenses;
};

export const calculateSalary = (prePayrollProfit) => {
  // =round(C8*(2/7),-3)
  return round(prePayrollProfit * (2 / 7)); // Hunter rounds to -3 places
};

export const calculateEmployerPayrollTax = (salary) => {
  // =C9*(0.0765)+If(C9>7000,(0.06*7000),(0.06*C9))
  let result = salary * 0.0765;
  result += salary > 7000 ? 0.06 * 7000 : 0.06 * salary;
  return result;
};

export const calculateEmployeePayrollTax = (salary) => {
  // =(C9)*0.0765
  return salary * 0.0765;
};

export const calculateProfitAfterPayroll = (prePayrollProfit, salary, employeePayrollTax) => {
  // =C8-C9-C10
  return prePayrollProfit - salary - employeePayrollTax;
};

// Section 199 Deduction (lesser of following two calculations)
export const calculateSection199Deduction = (salary, profitAfterPayroll) => {
  const adjustedSalary = salary * 0.5;
  const adjustedProfitAfterPayroll = profitAfterPayroll * 0.2; // profitAfterPayroll aka distribution
  return adjustedSalary < adjustedProfitAfterPayroll ? adjustedSalary : adjustedProfitAfterPayroll;
};

// 15.3% of 92.35%
export const calculateSelfEmploymentTax = (profit, section199Deduction) => {
  const adjustedProfit = profit - section199Deduction;
  return round(adjustedProfit * 0.153 * 0.9235);
};

export const calculateSelfEmploymentDeduction = (selfEmploymentTax) => {
  return selfEmploymentTax / 2;
};

export const calculateFederalIncomeTax = (revenue) => {
  let due = 0;
  for (const b of brackets) {
    if (revenue > b.top) {
      due += (b.top - b.bottom) * b.percent;
    } else {
      due += (revenue - b.bottom) * b.percent;
      break;
    }
  }
  due = round(due);
  return due;
};
