import styled from 'styled-components';
import { hummLightGreen } from '../../utils/colors';

const StyledHummCard = styled.div`
  /* width: 55%; */
  /* height: 180px; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* background-color: ${hummLightGreen}; */
  /* box-shadow: 3px 3px 6px 1px; */
  /* padding: 10px; */
  justify-content: center;
  margin-top: 60px;
`;

function HummCard({ children }) {
  return <StyledHummCard>{children}</StyledHummCard>;
}

export default HummCard;
