import Section from '../../containers/Section';
import { Heading } from '../text';
import HummAccordion from '../HummAccordion';
import { HummLinkButton } from '../buttons';
import { quiz } from '../../utils/routes';
import * as strings from '../../utils/strings';

const accordionItems = [
  { id: '1', heading: strings.faqQuestionOne, content: strings.faqAnswerOne },
  { id: '2', heading: strings.faqQuestionTwo, content: strings.faqAnswerTwo },
  { id: '3', heading: strings.faqQuestionThree, content: strings.faqAnswerThree },
  { id: '4', heading: strings.faqQuestionFour, content: strings.faqAnswerFour },
  { id: '5', heading: strings.faqQuestionFive, content: strings.faqAnswerFive },
  { id: '6', heading: strings.faqQuestionSix, content: strings.faqAnswerSix },
];

function FAQ({ height, backgroundOn }) {
  return (
    <Section colorType='primary' id='faq' backgroundOn={backgroundOn}>
      <Heading colorType='dark' marginBottom='medium'>
        {strings.faqHeader}
      </Heading>
      <HummAccordion items={accordionItems} />
      <HummLinkButton to={quiz} colorType='light'>
        {strings.seeIfFit}
      </HummLinkButton>
    </Section>
  );
}

export default FAQ;
