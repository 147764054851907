import Applicant from '../models/applicant';
import { uploadApplicant } from '../services/airtableService';
import { calculateHummSavings } from '../utils/taxMaths/savingsCalculator';

export const isEligible = (income) => {
  const lastYearIncomeArr = income ? income.replace('+', '').split('-') : 0;
  const lastYearIncomeMin = lastYearIncomeArr[0];
  // console.log(`IsEligible: ${lastYearIncomeMin >= 150000}`);
  return lastYearIncomeMin >= 150000;
};

export const calculateSavings = (income, expenses) => {
  const lastYearIncomeArr = income ? income.replace('+', '').split('-') : 0;
  const lastYearIncomeMax = lastYearIncomeArr[lastYearIncomeArr.length - 1];
  const lastYearIncomeMin = lastYearIncomeArr[0];

  const lastYearExpensesArr = expenses ? expenses.replace('+', '').split('-') : 0;
  const lastYearExpensesMax = lastYearExpensesArr[lastYearExpensesArr.length - 1];
  const lastYearExpensesMin = lastYearExpensesArr[0];

  const lower = calculateHummSavings(lastYearIncomeMin, lastYearExpensesMin);
  const upper = calculateHummSavings(lastYearIncomeMax, lastYearExpensesMax);

  return { lower: lower, upper: upper };
};

const createApplicantFromQuizResponses = (quizResponses) => {
  const applicant = new Applicant();
  applicant.setEmail(quizResponses.email);
  applicant.setIndustry(
    quizResponses.industry === 'other'
      ? `other - ${quizResponses.industryMetadata.userOption}`
      : quizResponses.industry
  );
  applicant.setLocation(quizResponses.location);
  applicant.setTaxHistory(
    quizResponses.taxHistory === 'Other'
      ? `other - ${quizResponses.taxHistoryMetadata.userOption}`
      : quizResponses.taxHistory
  );
  applicant.addMetrics(2020, quizResponses.lastYearIncome, quizResponses.lastYearExpenses);
  applicant.addMetrics(2021, quizResponses.thisYearIncome, quizResponses.thisYearExpenses);
  // console.log(`Applicant: ${JSON.stringify(applicant)}`);
  return applicant;
};

export const uploadEligible = (quizResponses) => {
  // console.log(`Uploading Eligible: ${JSON.stringify(quizResponses)}`);
  const applicant = createApplicantFromQuizResponses(quizResponses);
  applicant.setIsEligible(true);
  uploadApplicant(applicant);
};

export const uploadIneligible = (quizResponses) => {
  // console.log(`Uploading Ineligible: ${JSON.stringify(quizResponses)}`);
  const applicant = createApplicantFromQuizResponses(quizResponses);
  applicant.setIsEligible(false);
  uploadApplicant(applicant);
};
