import styled from 'styled-components';
import { hummNeonYellow } from '../utils/colors';
import * as strings from '../utils/strings';

const StyledEmailLink = styled.a`
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${hummNeonYellow};
  }
`;

function EmailLink(props) {
  return (
    <StyledEmailLink href={`mailto:${strings.adminEmailAddress}`}>{props.children}</StyledEmailLink>
  );
}

export default EmailLink;
