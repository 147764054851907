import styled from 'styled-components';
import Section from '../../containers/Section';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { Heading, Paragraph } from '../text';
import HummPrice from '../HummPrice';
import HummBulletedList from '../HummBulletedList';
import { HummLinkButton } from '../buttons';
import { quiz } from '../../utils/routes';
import * as strings from '../../utils/strings';

const BulletContainer = styled.div`
  margin-top: ${(props) => props.theme.layout.margin.medium};
`;

const BulletRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: ${(props) => props.theme.layout.margin.medium};
`;

const BulletList = styled(HummBulletedList)`
  width: 35%;
`;

const TabletAndMobileBulletList = styled(HummBulletedList)`
  margin-bottom: ${(props) => props.theme.layout.margin.medium};
`;

function Pricing({ height, backgroundOn }) {
  return (
    <Section colorType='primary' id='pricing' backgroundOn={backgroundOn}>
      <Heading colorType='dark'>{strings.pricingHeader}</Heading>
      <Paragraph colorType='light'>{strings.pricingParagraph}</Paragraph>

      {/* Pricing */}
      <HummPrice price={strings.pricingPriceOne} desc={strings.pricingPriceOneDesc} />
      <HummPrice price={strings.pricingPriceTwo} desc={strings.pricingPriceTwoDesc} />

      {/* Bullets */}
      <BulletContainer>
        <Desktop>
          <BulletRow>
            <BulletList
              title={strings.pricingFormation}
              bullets={strings.pricingFormationBullets}
              colorType='light'
            />
            <BulletList
              title={strings.pricingTax}
              bullets={strings.pricingTaxBullets}
              colorType='light'
            />
          </BulletRow>
          <BulletRow>
            <BulletList
              title={strings.pricingAccounting}
              bullets={strings.pricingAccountingBullets}
              colorType='light'
            />
            <BulletList
              title={strings.pricingPayroll}
              bullets={strings.pricingPayrollBullets}
              colorType='light'
            />
          </BulletRow>
        </Desktop>

        <TabletAndMobile>
          <TabletAndMobileBulletList
            title={strings.pricingFormation}
            bullets={strings.pricingFormationBullets}
            colorType='light'
          />
          <TabletAndMobileBulletList
            title={strings.pricingTax}
            bullets={strings.pricingTaxBullets}
            colorType='light'
          />
          <TabletAndMobileBulletList
            title={strings.pricingAccounting}
            bullets={strings.pricingAccountingBullets}
            colorType='light'
          />
          <TabletAndMobileBulletList
            title={strings.pricingPayroll}
            bullets={strings.pricingPayrollBullets}
            colorType='light'
          />
        </TabletAndMobile>
      </BulletContainer>

      <HummLinkButton to={quiz}>{strings.startHummin}</HummLinkButton>
    </Section>
  );
}

export default Pricing;
