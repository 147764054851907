import { useState, useEffect } from 'react';
import { fetchPostList } from '../../controllers/blogController';
import styled from 'styled-components';
import BlogCTA from './BlogCTA';
import PostCard from './PostCard';
import LoadingIcon from '../LoadingIcon';
import { BlogLayout } from './BlogLayout';

const LoadingContainer = styled.div`
  height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

function PostList() {
  const [isLoading, setIsLoading] = useState(true);
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      const result = await fetchPostList();
      setPostList(result);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <BlogLayout left={<BlogCTA />}>
      {!isLoading && (
        <div>
          {postList.map((post) => (
            <PostCard post={post} />
          ))}
        </div>
      )}
      {isLoading && (
        <LoadingContainer>
          <LoadingIcon />
        </LoadingContainer>
      )}
    </BlogLayout>
  );
}

export default PostList;
