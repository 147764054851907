import { useEffect } from 'react';
import { PageLayout } from './PageLayout';
import { privacyHtml } from './privacyPolicyContent';
// import HtmlToReact from 'html-to-react';
// import InnerHTML from 'dangerously-set-html-content';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <PageLayout>
      <div dangerouslySetInnerHTML={{ __html: privacyHtml }} />
      {/* <InnerHTML html={privacyHtml} /> */}
      {/* <div html={reactHtml} /> */}
    </PageLayout>
  );
}

export default PrivacyPolicy;
