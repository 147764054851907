import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { Paragraph } from '../text';
import { fetchYearEndUrl } from '../../controllers/fileController';
import { HummDownloadButton } from '../buttons';
import HummInput from '../HummInput';
import { QuizContainer, QuizHeading, QuizSubheading } from '../quiz/QuizLayout';
import validator from 'email-validator';
import * as strings from '../../utils/strings';

const ButtonContainer = styled.div`
  margin: auto;
`;

function LeadYearEnd({ id, onChange, onSubmit }) {
  const [value, setValue] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchUrl() {
      const fetchedUrl = await fetchYearEndUrl();
      setUrl(fetchedUrl);
    }
    fetchUrl();
  }, []);

  const handleChange = (event) => {
    setError('');
    setValue(event.target.value);
  };

  const handleClick = () => {
    const isValidEmail = validator.validate(value);
    if (isValidEmail) {
      onSubmit(value);
    } else {
      setError(strings.errorInvalidEmail);
    }
  };

  return (
    <QuizContainer>
      <Desktop>
        <QuizHeading centered>{strings.leadYearEndHeading}</QuizHeading>
      </Desktop>
      <TabletAndMobile>
        <QuizHeading centered fontSize='large' fontWeight='bold' marginBottom='medium'>
          {strings.leadYearEndHeading}
        </QuizHeading>
      </TabletAndMobile>
      <QuizSubheading centered={true}>{strings.leadYearEndSubheading}</QuizSubheading>
      <HummInput id={id} onChange={handleChange}>
        {strings.quizEmailPlaceholder}
      </HummInput>
      {error !== '' && <Paragraph colorType='error'>{error}</Paragraph>}
      <ButtonContainer>
        <HummDownloadButton
          disabled={value === '' || error !== ''}
          downloadUrl={url}
          onClick={handleClick}
        >
          {strings.buttonDownload}
        </HummDownloadButton>
      </ButtonContainer>
    </QuizContainer>
  );
}

export default LeadYearEnd;
