import { useState } from 'react';
import { QuizLayout, QuizSubheading } from './QuizLayout';
import { RadioButtonGroup } from '../buttons';
import HummInput from '../HummInput';
import * as strings from '../../utils/strings';

const getDefaultValue = (id, responses, options) => {
  if (responses[id]) {
    return options[responses[`${id}Metadata`].optionIndex];
  } else {
    return '';
  }
};

const getUserOption = (id, responses, options) => {
  if (responses[id] && responses[`${id}Metadata`].optionIndex === options.length - 1) {
    return responses[`${id}Metadata`].userOption;
  } else {
    return '';
  }
};

function QuizTaxInfo({ id, stepNum, totalSteps, onChange, onSubmit, onBack, responses }) {
  const [option, setOption] = useState(getDefaultValue(id, responses, strings.quiz3Options));
  const [userOption, setUserOption] = useState(getUserOption(id, responses, strings.quiz3Options));

  const handleRadioChange = (event) => {
    setOption(event.target.value);
    onChange(id, {
      target: {
        value: event.target.value,
        metadata: {
          optionIndex:
            strings.quiz3Options.indexOf(event.target.value) === -1
              ? strings.quiz3Options.length - 1
              : strings.quiz3Options.indexOf(event.target.value),
          userOption: userOption,
        },
      },
    });
  };

  const handleInputChange = (event) => {
    setUserOption(event.target.value);
    onChange(id, {
      target: {
        value: option,
        metadata: {
          optionIndex: strings.quiz3Options.indexOf(option),
          userOption: event.target.value,
        },
      },
    });
  };

  const isDisabled = () => {
    if (option !== '' && option !== 'Other') return false;
    if (option === 'Other' && userOption !== '') return false;
    return true;
  };

  return (
    <QuizLayout
      stepNum={stepNum}
      totalSteps={totalSteps}
      heading={strings.quiz3Heading}
      buttonText={strings.buttonNext}
      onSubmit={onSubmit}
      onBack={onBack}
      disabled={isDisabled()}
    >
      <QuizSubheading marginBottom='xsmall'>{strings.quiz3Question}</QuizSubheading>
      <RadioButtonGroup
        options={strings.quiz3Options}
        onChange={handleRadioChange}
        defaultValue={option}
      />
      <HummInput
        disabled={option !== 'Other'}
        id={id}
        onChange={handleInputChange}
        defaultValue={userOption}
      >
        {strings.quiz3Placeholder}
      </HummInput>
    </QuizLayout>
  );
}

export default QuizTaxInfo;
