import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { hummNeonYellow } from '../utils/colors';

const StyledRouterNavLink = styled(Link)`
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${hummNeonYellow};
  }
`;

function RouterNavLink(props) {
  return <StyledRouterNavLink to={props.to}>{props.children}</StyledRouterNavLink>;
}

export default RouterNavLink;
