import styled from 'styled-components';
import { hummGray } from '../utils/colors';

const Table = styled.table`
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  border-radius: 6px;
  box-shadow: 1px 1px 8px;
  table-layout: fixed;
`;

const TableRow = styled.tr`
  height: 55px;
`;

const TableColumnHeader = styled.th`
  text-align: center;
  color: ${(props) => (props.textColor ? props.textColor : '#0e2f31')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  border: 1px solid ${hummGray};
  border-collapse: collapse;
  padding: 10px 10px 10px 10px;
`;

const TableRowHeader = styled.td`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  border-collapse: collapse;
  border: 1px solid ${hummGray};
  padding: 10px 10px 10px 10px;
`;

const TableContent = styled.td`
  color: ${(props) => (props.textColor ? props.textColor : '#0e2f31')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  text-align: center;
  border-collapse: collapse;
  border: 1px solid ${hummGray};
`;

function HummInfoTable({
  data,
  hasRowHeaders,
  highlightColumnIndex,
  highlightColor,
  highlightTextColor,
  className,
}) {
  return (
    <Table className={className}>
      <TableRow>
        {data.headers.map((header, index) =>
          index === highlightColumnIndex ? (
            <TableColumnHeader backgroundColor={highlightColor} textColor={highlightTextColor}>
              {header}
            </TableColumnHeader>
          ) : (
            <TableColumnHeader>{header}</TableColumnHeader>
          )
        )}
      </TableRow>
      {data.rows.map((row) => (
        <TableRow>
          {row.map((item, index) =>
            hasRowHeaders && index === 0 ? (
              index === highlightColumnIndex ? (
                <TableRowHeader backgroundColor={highlightColor}>{item}</TableRowHeader>
              ) : (
                <TableRowHeader>{item}</TableRowHeader>
              )
            ) : index === highlightColumnIndex ? (
              <TableContent backgroundColor={highlightColor} textColor={highlightTextColor}>
                {item}
              </TableContent>
            ) : (
              <TableContent>{item}</TableContent>
            )
          )}
        </TableRow>
      ))}
    </Table>
  );
}

export default HummInfoTable;
