import styled from 'styled-components';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import Header from '../Header';
import Footer from '../Footer';

const Container = styled.div``;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResponsiveContentContainer = styled.div`
  min-height: 600px;
  padding: 20px 20px 20px 20px;
  margin: auto;
`;

export const Sidebar = styled.div`
  width: 25%;
  padding: 60px 60px 60px 60px;
`;

const Content = styled.div`
  width: 50%;
  min-height: 800px;
  padding: 60px 0 60px 0;
  margin: auto;
`;

export const PageLayout = ({ left, children, right }) => (
  <Container>
    <Header />
    <Desktop>
      <ContentContainer>
        <Sidebar>{left}</Sidebar>
        <Content>{children}</Content>
        <Sidebar>{right}</Sidebar>
      </ContentContainer>
    </Desktop>
    <TabletAndMobile>
      <ResponsiveContentContainer>
        {left}
        {children}
      </ResponsiveContentContainer>
    </TabletAndMobile>
    <Footer />
  </Container>
);
