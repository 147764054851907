import {
  calculateSelfEmploymentTax,
  calculateFederalIncomeTax,
  calculateSection199Deduction,
  calculatePrePayrollProfit,
  calculateSelfEmploymentDeduction,
  calculateSalary,
  calculateEmployerPayrollTax,
  calculateEmployeePayrollTax,
  calculateProfitAfterPayroll,
} from './taxCalculators';
import { round } from './math';

const expensesToRunSCorp = 6000;

export const calculateHummSavings = (revenue, expenses) => {
  const solePropTaxes = calculateSolePropTaxes(revenue, expenses);
  const sCorpTaxes = calculateSCorpTaxes(revenue, expenses);
  const savings = solePropTaxes - sCorpTaxes - expensesToRunSCorp;
  return round(savings);
};

export const calculateSolePropTaxes = (revenue, expenses) => {
  const profit = calculatePrePayrollProfit(revenue, expenses);
  const section199Deduction = calculateSection199Deduction(0, profit);
  const adjustedProfit = profit - section199Deduction;
  const selfEmploymentTax = calculateSelfEmploymentTax(adjustedProfit, section199Deduction);
  const selfEmploymentDeduction = calculateSelfEmploymentDeduction(selfEmploymentTax);
  const taxableIncome = adjustedProfit - selfEmploymentDeduction;
  const federalIncomeTax = calculateFederalIncomeTax(taxableIncome);
  const totalTax = selfEmploymentTax + federalIncomeTax;
  return round(totalTax);
};

export const calculateSCorpTaxes = (revenue, expenses) => {
  const prePayrollProfit = calculatePrePayrollProfit(revenue, expenses);
  const salary = calculateSalary(prePayrollProfit);
  const employerPayrollTax = calculateEmployerPayrollTax(salary);
  const employeePayrollTax = calculateEmployeePayrollTax(salary);
  const profitAfterPayroll = calculateProfitAfterPayroll(
    prePayrollProfit,
    salary,
    employeePayrollTax
  );
  const section199Deduction = calculateSection199Deduction(salary, profitAfterPayroll);

  const profitAfterSalaryAnd199 = profitAfterPayroll - section199Deduction;
  const incomeSubjectToTax = profitAfterSalaryAnd199 + salary;
  const totalPayrollTax = employerPayrollTax + employeePayrollTax;
  const federalIncomeTax = calculateFederalIncomeTax(incomeSubjectToTax);
  const totalTax = federalIncomeTax + totalPayrollTax;
  return totalTax;
};
