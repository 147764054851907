import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { Heading, Subheading } from '../text';
import textLogo from '../../assets/HUMM-_PrimaryLogo-Green2.png';

const Container = styled.div`
  padding: 60px 180px 180px 180px;
`;

const TabletAndMobileContainer = styled.div`
  padding: 20px 20px 20px 20px;
`;

const ContentContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const TabletAndMobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const NavLogo = styled.img`
  height: 60px;
  margin-bottom: 50px;
  cursor: pointer;
`;

const StyledHeading = styled(Heading)`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const StyledSubheading = styled(Subheading)`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

export const HummContentContainer = ({ onSubmit, children }) => (
  // <form onSubmit={onSubmit}>
  <div>
    <Desktop>
      <Container>
        <Link to='/'>
          <NavLogo src={textLogo} />
        </Link>
        <ContentContainer>{children}</ContentContainer>
      </Container>
    </Desktop>
    <TabletAndMobile>
      <TabletAndMobileContainer>
        <Link to='/'>
          <NavLogo src={textLogo} />
        </Link>
        <TabletAndMobileContentContainer>{children}</TabletAndMobileContentContainer>
      </TabletAndMobileContainer>
    </TabletAndMobile>
  </div>
);

export const HummHeading = ({
  className,
  children,
  fontSize,
  fontWeight,
  marginBottom,
  centered,
}) => (
  <StyledHeading
    className={className}
    fontSize={fontSize ? fontSize : 'xlarge'}
    fontWeight={fontWeight}
    colorType='dark'
    marginBottom={marginBottom}
    centered={centered ? centered : false}
  >
    {children}
  </StyledHeading>
);

export const HummSubheading = ({
  className,
  children,
  fontSize,
  colorType,
  marginBottom,
  centered,
}) => (
  <StyledSubheading
    className={className}
    fontSize={fontSize ? fontSize : 'large'}
    colorType={colorType ? colorType : 'primary'}
    marginBottom={marginBottom}
    centered={centered ? centered : false}
  >
    {children}
  </StyledSubheading>
);
