import styled from 'styled-components';
import {
  getFontFamily,
  getFontSize,
  getFontWeight,
  getFontColor,
  getMarginBottom,
} from './themeGetters';

const StyledH2 = styled.h2`
  text-align: center;
  font-family: ${(props) => getFontFamily(props)};
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => getFontWeight(props)};
  color: ${(props) => getFontColor(props)};
  padding: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: ${(props) => getMarginBottom(props)};
  margin-left: 0;
`;

function H2({
  className,
  font = 'title',
  fontSize = 'xxlarge',
  fontWeight = 'regular',
  colorType = 'primary',
  marginBottom = 'xsmall',
  children,
}) {
  return (
    <StyledH2
      className={className}
      font={font}
      fontSize={fontSize}
      fontWeight={fontWeight}
      colorType={colorType}
      marginBottom={marginBottom}
    >
      {children}
    </StyledH2>
  );
}

export default H2;
