import Section from '../../containers/Section';
import { Heading, Subheading, Paragraph } from '../text';
import { GetStartedButton } from '../buttons';
import { scrollDuration } from '../../utils/constants';
import * as strings from '../../utils/strings';

function Frontpage({ height, onMeasure, backgroundOn }) {
  return (
    <Section
      height={height}
      colorType='light'
      id='frontpage'
      onMeasure={onMeasure}
      backgroundOn={backgroundOn}
    >
      <Heading colorType='primary'>{strings.frontpageHeader}</Heading>
      <Subheading>{strings.frontpageSubheader}</Subheading>
      <Paragraph>{strings.frontpageParagraph}</Paragraph>
      <GetStartedButton to='whatWeDo' duration={scrollDuration} />
    </Section>
  );
}

export default Frontpage;
