import { useState } from 'react';
import { QuizLayout, QuizSubheading } from './QuizLayout';
import HummSelect from '../HummSelect';
import * as strings from '../../utils/strings';

const getDefaultValue = (id, responses, options) => {
  if (responses[id]) {
    return options[responses[`${id}Metadata`]];
  } else {
    return '';
  }
};

function QuizLocation({ id, stepNum, totalSteps, onChange, onSubmit, onBack, responses }) {
  const [defaultValue, setDefaultValue] = useState(
    getDefaultValue(id, responses, strings.stateOptions)
  );
  const [madeSelection, setMadeSelection] = useState(defaultValue ? true : false);

  const handleChange = (selectedOption) => {
    setMadeSelection(true);
    onChange(`${id}`, {
      target: {
        value: selectedOption.value,
        metadata: strings.stateOptions.indexOf(selectedOption),
      },
    });
  };

  return (
    <QuizLayout
      stepNum={stepNum}
      totalSteps={totalSteps}
      heading={strings.quiz1Heading}
      buttonText={strings.buttonNext}
      onSubmit={onSubmit}
      onBack={onBack}
      disabled={!madeSelection}
    >
      <QuizSubheading marginBottom='xlarge'>{strings.quiz1Question}</QuizSubheading>
      <HummSelect
        options={strings.stateOptions}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </QuizLayout>
  );
}

export default QuizLocation;
