import styled from 'styled-components';
import { QuizContainer, QuizHeading, QuizSubheading, QuizSmallThankYou } from './QuizLayout';
import { HummButton } from '../buttons';
import HummInput from '../HummInput';
import * as strings from '../../utils/strings';

const StyledQuizHeading = styled(QuizHeading)`
  text-align: center;
`;

const StyledQuizSubheading = styled(QuizSubheading)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: auto;
`;

const StyledEmailLink = styled.a`
  margin: auto;
`;

function QuizIneligible({ id, onChange, onSubmit }) {
  return (
    <QuizContainer onSubmit={onSubmit}>
      <QuizSmallThankYou />
      <StyledQuizHeading marginBottom='medium' centered={true}>
        {strings.quizRejectionHeading}
      </StyledQuizHeading>
      <StyledQuizSubheading marginBottom='small'>
        {strings.quizRejectionSubheading1}
      </StyledQuizSubheading>
      <StyledQuizSubheading colorType='dark' fontSize='small' marginBottom='medium'>
        {strings.quizRejectionSubheading2}
      </StyledQuizSubheading>
      <StyledEmailLink href={`mailto:${strings.adminEmailAddress}`}>
        {strings.adminEmailAddress}
      </StyledEmailLink>
      {/* <HummInput id={id} onChange={(e) => onChange(id, e)}>
        {strings.quizEmailPlaceholder}
      </HummInput> */}
      <ButtonContainer>
        <HummButton>{strings.buttonDone}</HummButton>
      </ButtonContainer>
    </QuizContainer>
  );
}

export default QuizIneligible;
