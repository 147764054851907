import { useState } from 'react';
import styled from 'styled-components';
import { QuizContainer, QuizHeading, QuizSubheading, QuizSmallThankYou } from './QuizLayout';
import { Paragraph } from '../text';
import { HummButton } from '../buttons';
import HummInput from '../HummInput';
import validator from 'email-validator';
import * as strings from '../../utils/strings';

const StyledQuizHeading = styled(QuizHeading)`
  text-align: center;
`;

const StyledQuizSubheading = styled(QuizSubheading)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: auto;
`;

function QuizEmail({ id, onChange, onSubmit }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleChange = (event) => {
    setError('');
    setEmail(event.target.value);
    onChange(id, event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValidEmail = validator.validate(email);
    if (isValidEmail) {
      onSubmit(event);
    } else {
      setError(strings.errorInvalidEmail);
    }
  };

  return (
    <QuizContainer
      onSubmit={handleSubmit}
      heading={strings.quiz1Heading}
      buttonText={strings.buttonContinue}
    >
      <QuizSmallThankYou />
      <StyledQuizHeading marginBottom='medium'>{strings.quizEmailHeading}</StyledQuizHeading>
      <StyledQuizSubheading marginBottom='medium'>
        {strings.quizEmailSubheading1}
      </StyledQuizSubheading>
      <StyledQuizSubheading marginBottom='xlarge'>
        {strings.quizEmailSubheading2}
      </StyledQuizSubheading>
      <HummInput id={id} onChange={handleChange}>
        {strings.quizEmailPlaceholder}
      </HummInput>
      {error !== '' && <Paragraph colorType='error'>{error}</Paragraph>}
      <ButtonContainer>
        <HummButton disabled={!email}>{strings.buttonSubmit}</HummButton>
      </ButtonContainer>
    </QuizContainer>
  );
}

export default QuizEmail;
