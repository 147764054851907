import styled from 'styled-components';
import { Subheading } from '../text';
import { hummDarkBlue, hummLightGreen } from '../../utils/colors';
import chevronRightLight from '../../assets/chevronRightLight.png';

const Button = styled.button`
  background-color: ${(props) => (props.light ? hummLightGreen : hummDarkBlue)};
  border: 0px;
  cursor: pointer;
  margin-top: 100px;
  padding: 14px 18px 14px 18px;

  &:hover {
    opacity: 70%;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 20%;
    cursor: default;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TextRight = styled(Subheading)`
  margin: 0 18px 0 0;
  font-size: 1rem;
`;

const TextLeft = styled(Subheading)`
  margin: 0 0 0 18px;
  font-size: 1rem;
`;

const ChevronRight = styled.img`
  width: 8px;
  object-fit: contain;
`;

const ChevronLeft = styled.img`
  width: 8px;
  object-fit: contain;
  transform: rotate(180deg);
`;

function HummButton({ className, light, children, disabled, onClick, reversed }) {
  return (
    <Button
      className={className}
      light={light ? light : false}
      disabled={disabled}
      onClick={onClick}
    >
      {!reversed && (
        <Content>
          <TextRight colorType='light'>{children}</TextRight>
          <ChevronRight src={chevronRightLight} />
        </Content>
      )}

      {reversed && (
        <Content>
          <ChevronLeft src={chevronRightLight} />
          <TextLeft colorType='light'>{children}</TextLeft>
        </Content>
      )}
    </Button>
  );
}

export default HummButton;
