import { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { uploadYearEndLead } from '../controllers/leadController';
import { LeadYearEnd } from '../components/leadForm';

function LeadForm() {
  const [complete, setComplete] = useState(false);

  const handleSubmit = (value) => {
    uploadYearEndLead(value);
    setComplete(true);
  };

  return (
    <div>
      {!complete && <LeadYearEnd onSubmit={handleSubmit} />}
      {complete && (
        <Route>
          <Redirect to={`/thankYou`} />
        </Route>
      )}
    </div>
  );
}

export default LeadForm;
