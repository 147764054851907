import styled from 'styled-components';
import {
  getFontFamily,
  getFontSize,
  getFontWeight,
  getFontColor,
  getMarginBottom,
} from './themeGetters';

const StyledH3 = styled.h3`
  text-align: ${(props) => props.textAlign};
  font-family: ${(props) => getFontFamily(props)};
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => getFontWeight(props)};
  color: ${(props) => getFontColor(props)};
  padding: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: ${(props) => getMarginBottom(props)};
  margin-left: 0;
`;

function H3({
  className,
  textAlign = 'center',
  font = 'body',
  fontSize = 'large',
  fontWeight = 'bold',
  colorType = 'dark',
  marginBottom = 'medium',
  children,
}) {
  return (
    <StyledH3
      className={className}
      textAlign={textAlign}
      font={font}
      fontSize={fontSize}
      fontWeight={fontWeight}
      colorType={colorType}
      marginBottom={marginBottom}
    >
      {children}
    </StyledH3>
  );
}

export default H3;
