import React, { useState } from 'react';
import styled from 'styled-components';
import {
  isEligible,
  calculateSavings,
  uploadEligible,
  uploadIneligible,
} from '../controllers/quizController';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import {
  QuizPrimer,
  QuizLocation,
  QuizRole,
  QuizTaxInfo,
  QuizLastYear,
  QuizThisYear,
  QuizEmail,
  QuizResults,
  QuizIneligible,
  QuizThankYouEligible,
  QuizThankYouIneligible,
} from '../components/quiz';

// Quiz pages and ordering
const quizFlow = [
  { id: 'letsGetStarted', component: QuizPrimer },
  { id: 'location', component: QuizLocation },
  { id: 'industry', component: QuizRole },
  { id: 'taxHistory', component: QuizTaxInfo },
  { id: 'lastYear', component: QuizLastYear },
  { id: 'thisYear', component: QuizThisYear },
];

const eligibleFlow = [
  { id: 'email', component: QuizEmail },
  { id: 'savings', component: QuizResults },
  { id: 'thankYouSuccess', component: QuizThankYouEligible },
];

const ineligibleFlow = [
  { id: 'nextTime', component: QuizIneligible },
  { id: 'thankYou', component: QuizThankYouIneligible },
];
// ------------------------

const Container = styled.div``;

function Quiz() {
  const [responses, setResponses] = useState({});
  const [savings, setSavings] = useState({});
  const [quizComplete, setQuizComplete] = useState(false);
  const [eligible, setIsEligible] = useState(false);
  const [totalFlowComplete, setTotalFlowComplete] = useState(false);

  // const [lastYearIncome, setLastYearIncome] = useState(0);
  // const [lastYearExpenses, setLastYearExpenses] = useState(0);

  const [quizFlowIndex, setQuizFlowIndex] = useState(0);
  const [eligibleFlowIndex, setEligibleFlowIndex] = useState(0);
  const [ineligibleFlowIndex, setIneligibleFlowIndex] = useState(0);

  let { path } = useRouteMatch();

  const handleChange = (id, event) => {
    // console.log(`Event: ${JSON.stringify(event.target)}`);
    setResponses({
      ...responses,
      [id]: event.target.value,
      [`${id}Metadata`]: event.target.metadata,
    });
    // console.log(`responses: ${JSON.stringify(responses)}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (quizFlowIndex < quizFlow.length - 1) {
      setQuizFlowIndex(quizFlowIndex + 1);
    } else {
      // run eligibility logic
      setQuizComplete(true);
      setSavings(calculateSavings(responses.lastYearIncome, responses.lastYearExpenses));
      // console.log(`Savings: ${JSON.stringify(savings)}`);
      setIsEligible(isEligible(responses.lastYearIncome));
    }
  };

  const handleBack = () => {
    if (quizFlowIndex < quizFlow.length) {
      setQuizFlowIndex(quizFlowIndex - 1);
    }
  };

  const handleEligibleSubmit = (event) => {
    if (event) event.preventDefault();
    if (eligibleFlow[eligibleFlowIndex].id === 'email') {
      // upload to airtable
      uploadEligible(responses);
    }

    if (eligibleFlowIndex < eligibleFlow.length - 1) {
      setEligibleFlowIndex(eligibleFlowIndex + 1);
    } else {
      setTimeout(() => setTotalFlowComplete(true), 120000);
    }
  };

  const handleIneligibleSubmit = (event) => {
    // console.log(`Ineligible Submit`);
    if (event) event.preventDefault();
    if (ineligibleFlow[ineligibleFlowIndex].id === 'nextTime') {
      // upload to airtable
      uploadIneligible(responses);
    }

    if (ineligibleFlowIndex < ineligibleFlow.length - 1) {
      setIneligibleFlowIndex(ineligibleFlowIndex + 1);
    } else {
      setTimeout(() => setTotalFlowComplete(true), 120000);
    }
  };

  const getQuizPage = () => {
    const page = quizFlow[quizFlowIndex];
    return (
      <Route exact path={`${path}/${page.id}`}>
        <page.component
          id={page.id}
          stepNum={quizFlowIndex}
          totalSteps={quizFlow.length - 1}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onBack={handleBack}
          responses={responses}
        />
      </Route>
    );
  };

  const getEligiblePage = () => {
    const page = eligibleFlow[eligibleFlowIndex];

    const eligibleProps = {};
    if (page.id === 'savings') {
      // eligibleProps.revenue = lastYearIncome;
      // eligibleProps.expenses = lastYearExpenses;
      eligibleProps.savingsLower = savings.lower;
      eligibleProps.savingsUpper = savings.upper;
      eligibleProps.email = responses.email;
    }

    return (
      <Route exact path={`${path}/${page.id}`}>
        <page.component
          id={page.id}
          onChange={handleChange}
          onSubmit={handleEligibleSubmit}
          {...eligibleProps}
        />
      </Route>
    );
  };

  const getIneligiblePage = () => {
    const page = ineligibleFlow[ineligibleFlowIndex];
    return (
      <Route exact path={`${path}/${page.id}`}>
        <page.component id={page.id} onChange={handleChange} onSubmit={handleIneligibleSubmit} />
      </Route>
    );
  };

  return (
    <Container>
      <Switch>
        {totalFlowComplete && (
          <Route>
            <Redirect to={`/`} />
          </Route>
        )}

        {!quizComplete && (
          <div>
            {getQuizPage()}
            <Route>
              <Redirect to={`${path}/${quizFlow[quizFlowIndex].id}`} />
            </Route>
          </div>
        )}

        {quizComplete && eligible && (
          <div>
            {getEligiblePage()}
            <Route>
              <Redirect to={`${path}/${eligibleFlow[eligibleFlowIndex].id}`} />
            </Route>
          </div>
        )}

        {quizComplete && !eligible && (
          <div>
            {getIneligiblePage()}
            <Route>
              <Redirect to={`${path}/${ineligibleFlow[ineligibleFlowIndex].id}`} />
            </Route>
          </div>
        )}
      </Switch>
    </Container>
  );
}

export default Quiz;
