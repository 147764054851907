import styled from 'styled-components';
import { Subheading } from '../text';
import { hummDarkBlue, hummLightGreen } from '../../utils/colors';
import chevronRightLight from '../../assets/chevronRightLight.png';

const StyledLink = styled.a`
  text-decoration: none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* Buttony things */
  background-color: ${(props) => (props.light ? hummLightGreen : hummDarkBlue)};
  border: 0px;
  cursor: pointer;
  margin-top: 100px;
  padding: 14px 18px 14px 18px;

  opacity: ${(props) => (props.disabled ? '20%' : '100%')};

  &:hover {
    opacity: ${(props) => (props.disabled ? '20%' : '70%')};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 20%;
    cursor: default;
  }
`;

const Text = styled(Subheading)`
  margin: 0 18px 0 0;
  font-size: 1rem;
`;

const Chevron = styled.img`
  width: 8px;
  object-fit: contain;
  transform: rotate(90deg);
`;

function HummLinkButton({ className, downloadUrl, light, children, disabled, onClick }) {
  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
    } else {
      onClick();
    }
  };

  return (
    <div>
      <StyledLink
        onClick={handleClick}
        href={!disabled && downloadUrl}
        target='_blank'
        rel='noopener noreferrer'
        oncontextmenu='return false;'
      >
        <Content className={className} light={light} disabled={disabled}>
          <Text colorType='light'>{children}</Text>
          <Chevron src={chevronRightLight} />
        </Content>
      </StyledLink>
    </div>
  );
}

export default HummLinkButton;
