import { useState } from 'react';
import styled from 'styled-components';
import { animateScroll } from 'react-scroll';
import NavLink from '../components/NavLink';
import RouterNavLink from '../components/RouterNavLink';
import EmailLink from '../components/EmailLink';
import IconLink from '../components/IconLink';
import textLogo from '../assets/HUMM-_PrimaryLogo-Green2.png';
import instagramIcon from '../assets/Instagram.png';
import facebookIcon from '../assets/Facebook.png';
import linkedinIcon from '../assets/LinkedIn-2X.png';
import ContactCard from '../components/cards/ContactCard';
import { scrollDuration } from '../utils/constants';
import * as strings from '../utils/strings';

const sections = [
  { id: 'frontpage' },
  { id: 'whatWeDo', label: strings.navWhatWeDo },
  { id: 'howItWorks', label: strings.navHowItWorks },
  { id: 'pricing', label: strings.navPricing },
  { id: 'underTheHood', label: strings.navUnderTheHood },
  { id: 'faq', label: strings.navFaq },
];

const NavbarContainer = styled.div`
  width: 33%;
`;

const NavContent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  height: 100%;
  background: ${(props) => props.color};
  padding-top: 100px;
`;

const NavTabletMobileContent = styled.div`
  /* position: fixed; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 33%; */
  /* height: ${(props) => props.height - 100 + 'px'}; */
  background: ${(props) => props.color};
  padding-top: 100px;
  padding-bottom: 50px;
`;

const NavLogo = styled.img`
  height: 60px;
  margin-bottom: 30px;
  cursor: pointer;
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* margin-top: 60px; */
  /* margin-bottom: 10px; */
`;

export function Navbar() {
  const [active, setActive] = useState('');

  const handleSetActive = (to) => {
    setActive(to);
  };

  return (
    <NavbarContainer>
      <NavContent color={(props) => props.theme.colors.dark}>
        <NavLogo onClick={animateScroll.scrollToTop} src={textLogo} />
        {sections &&
          sections.map((section) => (
            <NavLink
              activeClass='active'
              to={section.id}
              spy={true}
              smooth={true}
              offset={0}
              duration={scrollDuration}
              handleSetActive={handleSetActive}
              isActive={section.id === active}
            >
              {section.label}
            </NavLink>
          ))}
        <RouterNavLink to='/questionnaire'>{strings.navGetStarted}</RouterNavLink>
        <RouterNavLink to='/blog'>{strings.navBlog}</RouterNavLink>
        <EmailLink>{strings.contactUs}</EmailLink>
        <ContactCard />
        <SocialContainer>
          <IconLink src={instagramIcon} to={strings.instagramUrl} />
          <IconLink src={facebookIcon} to={strings.facebookUrl} />
          <IconLink src={linkedinIcon} to={strings.linkedinUrl} />
        </SocialContainer>
      </NavContent>
    </NavbarContainer>
  );
}

export function NavbarTablet({ height }) {
  const [active, setActive] = useState('');

  const handleSetActive = (to) => {
    setActive(to);
  };

  return (
    <NavTabletMobileContent height={height} color={(props) => props.theme.colors.dark}>
      <NavLogo onClick={animateScroll.scrollToTop} src={textLogo} />
      {sections &&
        sections.map((section) => (
          <NavLink
            activeClass='active'
            to={section.id}
            spy={true}
            smooth={true}
            offset={0}
            duration={scrollDuration}
            handleSetActive={handleSetActive}
            isActive={section.id === active}
          >
            {section.label}
          </NavLink>
        ))}
      <RouterNavLink to='/questionnaire'>{strings.navGetStarted}</RouterNavLink>
      <RouterNavLink to='/blog'>{strings.navBlog}</RouterNavLink>
      <EmailLink>{strings.contactUs}</EmailLink>
      <ContactCard />
      <SocialContainer>
        <IconLink src={instagramIcon} to={strings.instagramUrl} />
        <IconLink src={facebookIcon} to={strings.facebookUrl} />
        <IconLink src={linkedinIcon} to={strings.linkedinUrl} />
      </SocialContainer>
    </NavTabletMobileContent>
  );
}
