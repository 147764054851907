import styled from 'styled-components';
import { Link } from 'react-scroll';
import { hummNeonYellow, hummWhite } from '../utils/colors';

const StyledNavLink = styled(Link)`
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: ${(props) => (props.isActive ? hummNeonYellow : hummWhite)};
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    color: ${hummNeonYellow};
  }
`;

function NavLink(props) {
  return (
    <StyledNavLink
      activeClass='active'
      to={props.to}
      spy={true}
      smooth={true}
      offset={0}
      duration={props.duration}
      isDynamic={true}
      onSetActive={props.handleSetActive}
      isActive={props.isActive}
    >
      {props.children}
    </StyledNavLink>
  );
}

export default NavLink;
