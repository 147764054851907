import * as blogService from '../services/airtableService';

export const fetchPostList = async () => {
  const postList = await blogService.fetchPostList();
  console.log(`Post List: ${JSON.stringify(postList)}`);
  return postList;
};

export const fetchPost = async (postSlug) => {
  const post = await blogService.fetchPost(postSlug);
  console.log(`Post: ${JSON.stringify(post)}`);
  return post;
};
