import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Paragraph } from '../text';
import arrow from '../../assets/circleArrow.png';
import * as strings from '../../utils/strings';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const Arrow = styled.img`
  height: 18px;
  object-fit: contain;
  transform: rotate(90deg);
  margin-right: 5px;
`;

const Text = styled(Paragraph)``;

function AllArticlesButton() {
  return (
    <StyledLink to='/blog'>
      <Container>
        <Arrow src={arrow} />
        <Text colorType='primary' marginBottom='none'>
          {strings.blogAllArticles}
        </Text>
      </Container>
    </StyledLink>
  );
}

export default AllArticlesButton;
