import { useState } from 'react';
import { HummContentContainer } from '../components/hummComponents/HummLayout';
import { Heading } from '../components/text';
import { InlineWidget } from 'react-calendly';
import { calendlyUrl } from '../utils/constants';
import { Redirect } from 'react-router-dom';

function Scheduler() {
  const [scheduled, setScheduled] = useState(false);

  window.addEventListener('message', (e) => {
    if (e.data.event === 'calendly.event_scheduled') {
      setScheduled(true);
    }
  });

  return (
    <div>
      {scheduled && <Redirect to='/thankYou' />}
      <HummContentContainer>
        <Heading>Schedule a Call</Heading>
        <InlineWidget url={calendlyUrl} />
      </HummContentContainer>
    </div>
  );
}

export default Scheduler;
