import { useState } from 'react';
import styled from 'styled-components';
import { Route, Redirect } from 'react-router-dom';
import { QuizContainer, QuizHeading, QuizSubheading } from '../quiz/QuizLayout';
import * as strings from '../../utils/strings';
import logo from '../../assets/HUMM-_Submark-Green2.png';

const Logo = styled.img`
  width: 40px;
  object-fit: contain;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function LeadThankYou() {
  const [redirect, setRedirect] = useState(false);

  const startTimer = () => {
    setTimeout(() => {
      setRedirect(true);
    }, 6000);
  };

  startTimer();
  return (
    <div>
      {!redirect && (
        <QuizContainer>
          <Container>
            <QuizHeading marginBottom='medium'>{strings.quizThankYouHeading}</QuizHeading>
            <QuizSubheading marginBottom='xlarge'>{strings.leadThankYou}</QuizSubheading>
            <Logo src={logo} />
          </Container>
        </QuizContainer>
      )}
      {redirect && (
        <Route>
          <Redirect to={`/`} />
        </Route>
      )}
    </div>
  );
}

export default LeadThankYou;
