import styled from 'styled-components';
import { Subtext, Paragraph } from '../text';
import BasicLink from '../BasicLink';
import logoDark from '../../assets/logoDark.png';
import * as strings from '../../utils/strings';

const Container = styled.div`
  height: 250px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.light};
  align-items: center;
`;

const Logo = styled.img`
  width: 40px;
  object-fit: contain;
  margin: 40px 0 30px 0;
`;

function Footer() {
  return (
    <Container>
      <Logo src={logoDark} />
      <Subtext colorType='dark' marginBottom='xsmall'>
        {strings.copyrightText}
      </Subtext>
      <Paragraph fontSize='xxsmall' colorType='dark' marginBottom='small'>
        {strings.footerText}
      </Paragraph>
      <BasicLink to='/privacy' colorType='primary' underline={false} hoverColor='dark'>
        {strings.privacyPolicyLink}
      </BasicLink>
    </Container>
  );
}

export default Footer;
