import { useState, useEffect } from 'react';
import { fetchPost } from '../../controllers/blogController';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { hummDarkBlue, hummGray } from '../../utils/colors';
import { Subheading, Subtext } from '../text';
import LoadingIcon from '../LoadingIcon';
import AllArticlesButton from './AllArticlesButton';
import { formatDate } from '../../utils/dates';
import BlogCTA from './BlogCTA';
import { BlogLayout } from './BlogLayout';

const LoadingContainer = styled.div`
  height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const PostContent = styled.div``;

const DateText = styled(Subtext)`
  color: ${hummGray};
  text-align: left;
`;

const Title = styled(Subheading)`
  font-size: 2rem;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const PostText = styled(ReactMarkdown)`
  color: ${hummDarkBlue};
`;

function Post({ match }) {
  console.log(`Match: ${match}`);
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState({});
  const {
    params: { postSlug },
  } = match;

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      const post = await fetchPost(postSlug);
      setPost(post);
      setIsLoading(false);
    }
    fetchData();
  }, [postSlug]);

  return (
    <BlogLayout left={<BlogCTA />}>
      {!isLoading && (
        <PostContent>
          <AllArticlesButton />
          <Title textAlign='left' fontSize='xlarge'>
            {post.title}
          </Title>
          <DateText>{formatDate(post.date)}</DateText>
          <Image src={post.headerImage[0].url} />
          <PostText>{post.postMarkdown}</PostText>
        </PostContent>
      )}
      {isLoading && (
        <LoadingContainer>
          <LoadingIcon />
        </LoadingContainer>
      )}
    </BlogLayout>
  );
}

export default Post;
