import styled from 'styled-components';
import { Paragraph } from '../text';
import circleArrow from '../../assets/circleArrow.png';
import { Link } from 'react-scroll';
import * as strings from '../../utils/strings';

const StyledLink = styled(Link)`
  margin-top: auto;
  text-decoration: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  align-items: center;
  &:hover {
    opacity: 60%;
  }
`;

const Image = styled.img`
  width: 30px;
  object-fit: contain;
`;

function GetStartedButton({ to, duration }) {
  return (
    <StyledLink
      activeClass='active'
      to={to}
      spy={true}
      smooth={true}
      offset={0}
      duration={duration}
    >
      <Container>
        <Paragraph colorType='primary' fontSize='small' fontWeight='bold' marginBottom='xsmall'>
          {strings.navGetStarted}
        </Paragraph>
        <Image src={circleArrow} />
      </Container>
    </StyledLink>
  );
}

export default GetStartedButton;
