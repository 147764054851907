import HummCard from './HummCard';
import { Heading, Paragraph } from '../text';
import BasicLink from '../BasicLink';
import * as strings from '../../utils/strings';

function ContactCard() {
  return (
    <HummCard>
      <Heading fontSize='large' fontWeight='bold' marginBottom='xxsmall' colorType='primary'>
        {strings.contactGetInTouch}
      </Heading>
      <Paragraph marginBottom='xxsmall' colorType='primary'>
        {strings.contactPhoneNumber}
      </Paragraph>
      {/* <Paragraph marginBottom='small'>or</Paragraph> */}
      <BasicLink to='/schedule' colorType='primary'>
        {strings.contactScheduleCall}
      </BasicLink>
    </HummCard>
  );
}

export default ContactCard;
