// Navigation / Buttons / Components
export const navWhatWeDo = 'WHAT WE DO';
export const navHowItWorks = 'HOW IT WORKS';
export const navUnderTheHood = 'UNDER THE HOOD';
export const navPricing = 'PRICING';
export const navFaq = 'FAQ';
export const navGetStarted = 'GET STARTED';
export const navBlog = 'BLOG';
export const quizStep = 'STEP';
export const buttonContinue = 'CONTINUE';
export const buttonNext = 'NEXT';
export const buttonSubmit = 'SUBMIT';
export const buttonSchedule = 'SCHEDULE';
export const buttonDone = 'DONE';
export const buttonDownload = 'DOWNLOAD';
export const contactUs = 'CONTACT US';
export const buttonBack = 'Back';

// Social Urls
export const instagramUrl = 'https://www.instagram.com/hummaccounting';
export const facebookUrl = 'https://www.facebook.com/hummaccounting';
export const linkedinUrl = 'https://www.linkedin.com/company/humm-accounting';

// Contact Card
export const contactGetInTouch = 'Get In Touch';
export const contactPhoneNumber = '(720) 689-4123';
export const contactScheduleCall = 'Schedule a call';

// Error Messages
export const errorInvalidEmail = 'Please enter a valid email address';

// Humm Constants
export const adminEmailAddress = 'admin@hummaccounting.com';
export const yearEndLeadFormStorageLocation = 'docs/Humm.pdf';

// ____________________ Homepage ____________________
// CTAs
export const getStartedNow = 'GET STARTED NOW';
export const estimateSavings = 'ESTIMATE YOUR SAVINGS';
export const startHummin = 'START HUMMIN’ NOW';
export const seeIfFit = 'SEE IF YOU’RE A GOOD FIT';

// Frontpage
export const frontpageHeader = ['Stop Stalling.', <br />, 'Start Hummin’.'];
export const frontpageSubheader = 'From payroll to bookkeeping, we’ll keep your business running.';
export const frontpageParagraph =
  'Wouldn’t it be nice if your tax strategy came with a check engine light? At Humm, we handle company formation, taxes, accounting, bookkeeping, and more. On average our clients save $15,000 or more in taxes.';

// What We Do
export const whatWeDoHeader = 'What We Do';
export const whatWeDoSubheader = 'It’s Time to Get You Hummin’';
export const whatWeDoFirstParagraph =
  'Tired of switching gears between being a business owner and being an accountant? Here’s how we can get you back into the driver’s seat:';
export const whatWeDoSecondParagraph =
  'Humm streamlines your bookkeeping, payroll, and tax filing process. Our automated service integrates business accounting and tax filing into one convenient location.';
export const whatWeDoThirdParagraph =
  'Spend less time checking and more time going with peace of mind that all your financial needs are being met!';

// How It Works
export const howItWorksHeader = 'How It Works';
export const howItWorksSubeader = 'Getting Started is Easy - let’s pop the hood and take a look!';
export const howItWorksFirstParagraph = 'Even the best businesses need the occasional ‘tune-up’.';
export const howItWorksSecondParagraph =
  'Our team takes care of the monthly and quarterly to-dos so everything gets done before the end of the year. What? No end of year scramble?! Trust us, it’s possible.';
export const howItWorksThirdParagraph =
  'You’ll get quarterly check ins with a CPA and monthly reports. Also, our in-house CPAs can file your taxes for you. If you already have a CPA, we will work closely with them to make sure everything is done properly and on time.';
export const howItWorksStep1 = '01.';
export const howItWorksStep1Desc = 'Take the Questionnaire';
export const howItWorksStep2 = '02.';
export const howItWorksStep2Desc = 'Schedule a Call';
export const howItWorksStep3 = '03.';
export const howItWorksStep3Desc = 'Start Saving';

// Under The Hood
export const underTheHoodHeader = 'Under the Hood';
export const underTheHoodSubheader = 'We do not handle any of the following:';
export const underTheHoodRemember =
  'Remember - your Quickbooks and Gusto fees are included with your Humm subscription. This isn’t always the case with other bookkeepers or CPA firms.';
export const underTheHoodList = [
  'City taxes or filings',
  'County taxes or filings',
  'State registration fees',
  'Insurance ',
  'Personal expenses',
];
export const underTheHoodTableData = {
  headers: ['', 'Quickbooks', 'Gusto', 'Aunt Bonnie', 'CPA Firm', 'Humm'],
  rows: [
    ['Monthly categorization', 'DIY', '-', 'X', '-', 'X'],
    ['Monthly reconciliation', 'DIY', '-', 'X', '-', 'X'],
    ['Monthly reports', 'DIY', 'DIY', 'maybe', '-', 'X'],
    ['Quarterly payroll', '-', 'DIY', 'X', '-', 'X'],
    ['Payroll calculation', '-', '-', '-', 'X', 'X'],
    ['Payroll reconciliation', '-', '-', 'maybe', 'X', 'X'],
    ['Entity formation', '-', '-', '-', 'maybe', 'X'],
    ['S-Corp filing', '-', '-', 'maybe', 'X', 'X'],
    ['Section 199 deduction', '-', '-', '-', 'X', 'X'],
    ['Business tax return', '-', '-', '-', 'X', 'X'],
    ['Personal tax return', '-', '-', '-', 'X', 'X'],
  ],
};

// Pricing
export const pricingHeader = 'Pricing';
export const pricingParagraph =
  'Humm is a tax deduction and will more than pay for itself in tax savings (We only work with clients who will save more than our services cost).';
export const pricingPriceOne = '$399';
export const pricingPriceOneDesc = '(if you already have your own tax preparer)';
export const pricingPriceTwo = '$549';
export const pricingPriceTwoDesc =
  '(if you would like Humm to prepare and file your tax returns for you)';
export const pricingPerMonth = '/month';

export const pricingFormation = 'Formation';
export const pricingFormationBullets = [
  '1:1 call with a CPA',
  'Filing a single-memberLLC in your state',
  'Tax ID Number (EIN) application',
];

export const pricingTax = 'Tax';
export const pricingTaxBullets = [
  'Paycheck calculator and Quarterly Estimated tax calculator',
  'Business income tax returns (federal and states)',
  'Individual income tax returns(federal and states)',
];

export const pricingAccounting = 'Accounting';
export const pricingAccountingBullets = [
  'Quickbooks Online Essentials ($40/m value)',
  'Customized set-up for QuickBooks Online',
  'Ongoing bookkeeping and accounting services',
];

export const pricingPayroll = 'Payroll';
export const pricingPayrollBullets = [
  'Automated payroll',
  'Gusto Core plan ($45/m value)',
  // 'Unlimited payroll runs',
  'Issue and file W-2s and 1099s',
  // 'Automatically report new hires to the government',
  'State-level employer registration',
];

// FAQ
export const faqHeader = 'Frequently Asked Questions';
export const faqQuestionOne = 'How does Humm’s subscription work?';
export const faqAnswerOne =
  'Humm is a monthly subscription service. We ask for a 6-month commitment to start. After 6 months you have the option of adjusting payments on a month-to-month basis. All payments are set up with autopay. ';
export const faqQuestionTwo = 'Why is it a 6-month commitment to start? Are there shorter options?';
export const faqAnswerTwo =
  'There are no shorter options to start. We want to ensure that we get things right. Our onboarding process is extensive and takes time for us to set up. We work hard to establish a good foundation for your tax strategy so that it will last. ';
export const faqQuestionThree = 'What’s included in the price?';
export const faqAnswerThree =
  'The price covers the following services: filing of your single-member LLC, S Corp tax election, getting your EIN, setting up monthly bookkeeping and bank reconciliations, quarterly check-ups with our team, and payroll processing. Depending on your business, it may include a yearly federal and state tax return. Our price also includes Gusto and QuickBooks Online Essentials. ';
export const faqQuestionFour = 'What’s NOT included in the price?';
export const faqAnswerFour =
  'Depending on your location, there can be additional state, and even city, filings and fees due. Humm does NOT cover the payment or initiate any filings other than those covered in the pricing section above.';
export const faqQuestionFive = 'Why didn’t I get accepted?';
export const faqAnswerFive =
  'At Humm, we want to make sure our clients get the most bang for their buck. We find that if your business makes under $120,000/year that our services may not be your best option. We keep our quality of service high by focusing on clients that will see the biggest return.';
export const faqQuestionSix = 'How does Humm save me money?';
export const faqAnswerSix =
  'We use the same small business strategies that keep our business hummin’ along. We leverage a tax provision known as Section 199 to make deductions from your salary that save you money. You may be asking yourself, can’t anyone do that? Sure, anyone can. You’re welcome to try it yourself!';
export const faqFinePrint =
  '*Based on average estimated tax savings of active Humm users with over $80,000 net revenue in 2019 ($12,217) and potential savings declared by Intuit Quickbooks TY17 US subscribers that have identified >$10,000 in income and >$0 in business expenses ($4,628)';

// Footer
export const copyrightText = '© 2021 Humm Accounting.';
export const footerText =
  'Humm Accounting is a self-help service that provides financial tools for your convenience. We do not provide legal or financial advice.';
export const privacyPolicyLink = 'Privacy Policy';
export const privacyPolicyHeader = 'Privacy Policy';
export const privacyPolicyText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

// ____________________ Quiz ____________________
// Primer
export const quizPrimerHeading =
  'We’re excited to learn more about your business and get you hummin’.';
export const quizPrimerSubheading =
  'Answer the following questions to the best of your ability. Don’t worry, it’s not a test! We’ll use your responses to see if you can save by switching to Humm. Afterward, you will have the option to schedule a call with one of our accountants.';
export const quizPrimerCTA = 'Ready to go for a test-drive?';
export const quizPrimerButton = 'Start your engines!';

export const quiz1Heading = 'Location';
export const quiz1Question = 'What state are you working from?';
export const quiz1Placeholder = 'Enter state';

export const quiz2Heading = 'Industry';
export const quiz2Question = 'What industry do you work in?';
export const quiz2Placeholder = 'Enter your industry';

export const quiz3Heading = 'Tax History';
export const quiz3Question = 'Last year, how did you file your taxes?';
export const quiz3QuestionSub = 'Please select one';
export const quiz3Options = ['Sole Proprietor', 'LLC', 'S-Corp (LLC)', 'S-Corp (C-Corp)', 'Other'];
export const quiz3Placeholder = 'Tell us how you filed';

export const quiz4Heading = 'Income';
export const quiz4Question1 = 'What was your 2020 income?';
export const quiz4Question2 = 'How much were your expenses in 2020?';

export const quiz5Heading = 'Income';
export const quiz5Question1 = 'What do you expect your 2021 income will be?';
export const quiz5Question2 = 'How much do you expect your expenses will be in 2021?';

export const quizEmailHeading = 'Let’s get you hummin’.';
export const quizEmailSubheading1 = 'Good news! Based on your responses, we think it’s a match.';
export const quizEmailSubheading2 =
  'Drop us your email below to see how much you can save annually by using Humm.';
export const quizEmailPlaceholder = 'Drop us your email';
export const quizEmailPlaceholder2 = 'Drop us your email to find out how';
export const quizThankYou = 'THANK YOU!';
export const quizResultsSubheading1 = [
  'Freelancers with',
  'in income and',
  'in expenses typically save between:',
];
export const quizResultsSubheading1alt =
  'Freelancers with similar income and expenses typically save between:';
export const quizResultsSubheading1alt2 =
  'Freelancers with similar income and expenses typically save at least:';
export const quizResultsSubheading2 = 'in taxes every year with Humm.';
export const quizResultsSubheading3 =
  'Pick any open time on the widget below to schedule a call with one of our accountants.';
export const quizRejectionHeading = 'Womp Womp.';
export const quizRejectionSubheading1 = 'Based on your responses, we don’t think it’s a fit.';
export const quizRejectionSubheading2 =
  'It’s not you. It’s us. We only take on clients whose savings potential will outweigh our cost. If you have a burning question, we are real humans (tax experts, even!) who will be happy to respond to an email.';
export const quizRejectionPlaceholder = 'Drop us your email';
export const quizThankYouHeading = 'Thanks!';
export const quizThankYouSubheading1 = 'We’re looking forward to meeting you!';
export const quizThankYouSubheading2 = 'We hope to stay in touch.';

export const industryOptions = [
  { value: 'accountingFinance', label: 'Accounting and Finance' },
  { value: 'administrative', label: 'Administrative' },
  { value: 'consulting', label: 'Consulting' },
  { value: 'customerService', label: 'Customer Service' },
  { value: 'education', label: 'Education and Training' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'medical', label: 'Medical and Health' },
  { value: 'photography', label: 'Photography' },
  { value: 'projectManagement', label: 'Project Management' },
  { value: 'realEstate', label: 'Real Estate' },
  { value: 'research', label: 'Research Analyst' },
  { value: 'retail', label: 'Retail' },
  { value: 'socialMedia', label: 'Social Media' },
  { value: 'software', label: 'Software Development' },
  { value: 'tech', label: 'Tech' },
  { value: 'writing', label: 'Writing' },
  { value: 'other', label: 'Other...' },
];

export const incomeOptions = [
  { value: '0-100000', label: '$0 - $100,000' },
  { value: '100000-150000', label: '$100,000 - $150,000' },
  { value: '150000-200000', label: '$150,000 - $200,000' },
  { value: '200000-250000', label: '$200,000 - $250,000' },
  { value: '250000-300000', label: '$250,000 - $300,000' },
  { value: '300000+', label: '$300,000+' },
];

export const expenseOptions = [
  { value: '0-10000', label: '$0 - $10,000' },
  { value: '10000-25000', label: '$10,000 - $25,000' },
  { value: '25000-50000', label: '$25,000 - $50,000' },
  { value: '50000-100000', label: '$50,000 - $100,000' },
  { value: '100000-200000', label: '$100,000 - $200,000' },
  { value: '200000+', label: '$200,000+' },
];

export const stateOptions = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

// ____________________ Lead Forms ____________________
export const leadYearEndHeading = 'Make sure you’re asking your bookkeper these 5 questions';
export const leadYearEndSubheading =
  'Drop us your email to download a PDF with 5 questions you must ask before year-end to ensure maximum tax savings.';
export const leadThankYou = 'We will be in touch!';
export const leadSavingsHeading = 'Caclulate Your Savings';
export const leadSavingsSubheading =
  'Use the calculator below to see how much you could annually save by using Humm';
export const leadSavingsResult = 'You Could Save';

// ____________________ Blog ____________________
export const blogReadMore = 'Read more';
export const blogAllArticles = 'All articles';
export const blogCTAText =
  'Stop trusting aunt Bonnie with your small business finances. Humm is a bookkeeping firm run by CPAs. Enter your email below to chat with one of them today. FOR FREE!';
export const blogCTATextShort =
  'Humm is a bookkeeping firm run by CPAs. Enter your email below to chat with one of them today. FOR FREE!';
