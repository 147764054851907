import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { hummNeonYellow, hummDarkBlue } from '../utils/colors';
import { getFontSize } from './text/themeGetters';

const StyledBasicLink = styled(Link)`
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  color: ${(props) => props.theme.text.color.primary};
  font-size: ${(props) => getFontSize(props)};
  margin-bottom: 20px;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${({ hoverColor }) => (hoverColor === 'light' ? hummNeonYellow : hummDarkBlue)};
  }
  margin-bottom: 0px;
`;

function BasicLink({ to, children, fontSize = 'medium', underline = true, hoverColor = 'light' }) {
  return (
    <StyledBasicLink to={to} fontSize={fontSize} underline={underline} hoverColor={hoverColor}>
      {children}
    </StyledBasicLink>
  );
}

export default BasicLink;
