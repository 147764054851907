import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Desktop, TabletAndMobile } from './Breakpoints';
import { Navbar, NavbarTablet } from './Navbar';
import {
  Frontpage,
  WhatWeDo,
  HowItWorks,
  UnderTheHood,
  Pricing,
  FAQ,
  Footer,
} from '../components/sections';
import { TopButton } from '../components/buttons';
import { animateScroll } from 'react-scroll';

// Desktop

const getSectionBreakpoints = (sectionHeight) => {
  const breakpoints = [];
  breakpoints.push(0);
  breakpoints.push(sectionHeight);
  breakpoints.push(sectionHeight);
  breakpoints.push(sectionHeight);
  breakpoints.push(sectionHeight * 1.5);
  breakpoints.push(sectionHeight * 1.5);
  breakpoints.push(sectionHeight * 1.5);
  // console.log(breakpoints);
  return breakpoints;
};

const getCurrentSectionIndex = (sectionBreakpoints, scrollPosition) => {
  let tally = 0;
  for (let i = 0; i < sectionBreakpoints.length; i++) {
    tally += sectionBreakpoints[i];
    if (tally > scrollPosition) {
      return i - 1;
    }
  }
};

const getPreviousTally = (sectionBreakpoints, index) => {
  let tally = 0;
  for (let i = index; i >= 0; i--) {
    tally += sectionBreakpoints[i];
  }
  return tally;
};

const getOpacity = (sectionBreakpoints, scrollPosition) => {
  if (scrollPosition) {
    const currentSectionIndex = getCurrentSectionIndex(sectionBreakpoints, scrollPosition);
    // console.log(`I: ${currentSectionIndex}`);

    const offset = scrollPosition - getPreviousTally(sectionBreakpoints, currentSectionIndex);
    const remaining = sectionBreakpoints[currentSectionIndex + 1] - offset;

    // console.log(`Offset: ${offset}`);
    // console.log(`Remaining: ${remaining}`);

    if (currentSectionIndex % 2 === 0) {
      // console.log(`Even`);
      return offset / remaining;
    } else {
      // console.log(`Odd`);
      return remaining / offset;
    }
  }
};

const getBackgrounColor = (sectionBreakpoints, scrollPosition) => {
  let opacity = getOpacity(sectionBreakpoints, scrollPosition);
  if (opacity > 1) opacity = 1;
  return `rgba(97, 190, 117, ${opacity})`;
};

function updateBackground(height, scrollPosition) {
  const sectionBreakpoints = getSectionBreakpoints(height);
  const color = getBackgrounColor(sectionBreakpoints, scrollPosition);
  return color;
}

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.color};
`;

const DesktopPageContent = styled.div`
  width: 67%;
`;
// Tablet and Mobile

function App() {
  const { height } = useWindowDimensions();
  const scrollPosition = useScrollPosition();
  const color = updateBackground(height, scrollPosition);

  return (
    <div>
      <Desktop>
        <DesktopContainer color={color}>
          <Navbar />
          <DesktopPageContent>
            <TopButton onClick={animateScroll.scrollToTop} />
            <Frontpage height={height} />
            <WhatWeDo height={height} />
            <HowItWorks height={height} />
            <Pricing height={height} />
            <UnderTheHood height={height} />
            <FAQ height={height} />
            <Footer />
          </DesktopPageContent>
        </DesktopContainer>
      </Desktop>
      <TabletAndMobile>
        <TopButton onClick={animateScroll.scrollToTop} />
        <NavbarTablet height={height} />
        <Frontpage backgroundOn={true} />
        <WhatWeDo backgroundOn={true} />
        <HowItWorks backgroundOn={true} />
        <Pricing backgroundOn={true} />
        <UnderTheHood backgroundOn={true} />
        <FAQ backgroundOn={true} />
        <Footer />
      </TabletAndMobile>
    </div>
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  function getScrollPosition() {
    const { pageYOffset: scrollPosition } = window;
    return scrollPosition;
  }

  function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState(getScrollPosition());

    useEffect(() => {
      function handleScroll() {
        setScrollPosition(getScrollPosition());
      }

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scrollPosition;
  }
}

export default App;
