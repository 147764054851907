import { useState } from 'react';
import styled from 'styled-components';
import { InlineWidget } from 'react-calendly';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { QuizContainer, QuizHeading, QuizSubheading, QuizSmallThankYou } from './QuizLayout';
import { HummButton } from '../buttons';
import { calendlyUrl } from '../../utils/constants';
import * as strings from '../../utils/strings';
const format = require('format-number');

const StyledQuizHeading = styled(QuizHeading)`
  text-align: center;
`;

const StyledQuizSubheading = styled(QuizSubheading)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: auto;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const CalendlyContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const StyledCalendlyWidget = styled(InlineWidget)`
  height: 1500px;
`;

const TabletAndMobileStyledCalendlyWidget = styled(InlineWidget)``;

const getComponentArray = (revenue, expenses) => {
  const componentArray = [
    <StyledQuizHeading colorType='dark' fontSize='large' marginBottom='small'>
      {strings.quizResultsSubheading1[0]}
    </StyledQuizHeading>,
    <StyledQuizHeading colorType='dark' fontSize='large' fontWeight='bold' marginBottom='small'>
      {`\u00A0$${format()(revenue)}\u00A0`}
    </StyledQuizHeading>,
    <StyledQuizHeading colorType='dark' fontSize='large' marginBottom='small'>
      {strings.quizResultsSubheading1[1]}
    </StyledQuizHeading>,
    <StyledQuizHeading colorType='dark' fontSize='large' fontWeight='bold' marginBottom='small'>
      {`\u00A0$${format()(expenses)}\u00A0`}
    </StyledQuizHeading>,
    <StyledQuizHeading colorType='dark' fontSize='large' marginBottom='small'>
      {strings.quizResultsSubheading1[2]}
    </StyledQuizHeading>,
  ];

  return componentArray;
};

// Calendly.initInlineWidget({
//   url: 'https://calendly.com/YOURLINK',
//   parentElement: document.getElementById('SAMPLEdivID'),
//   prefill: {},
//   utm: {},
// });

function CalendlyButton() {
  const head = document.querySelector('head');
  const script = document.createElement('script');
  script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
  head.appendChild(script);
  return (
    <div>
      <div data-url='https://calendly.com/elliotsperling/30min' />
    </div>
  );

  // <div>
  //       <link href='https://assets.calendly.com/assets/external/widget.css' rel='stylesheet' />
  //       <script
  //         src='https://assets.calendly.com/assets/external/widget.js'
  //         type='text/javascript'
  //       ></script>
  //       <a
  //         href=''
  //         onClick="Calendly.initPopupWidget({url: 'https://calendly.com/elliotsperling/30min'});return false;"
  //       >
  //         Schedule time with me
  //       </a>
  //     </div>
}

function QuizResults({ onSubmit, revenue, expenses, email, savingsLower, savingsUpper }) {
  const [scheduled, setScheduled] = useState(false);

  window.addEventListener('message', (e) => {
    if (e.data.event === 'calendly.event_scheduled') {
      setScheduled(true);
    }
  });

  return (
    <QuizContainer
      onSubmit={onSubmit}
      heading={strings.quiz1Heading}
      buttonText={strings.buttonContinue}
    >
      <QuizSmallThankYou />
      {/* <RangeContainer>{getComponentArray(revenue, expenses)}</RangeContainer> */}
      {savingsLower !== savingsUpper && (
        <div>
          <StyledQuizHeading colorType='dark' fontSize='large' marginBottom='small'>
            {strings.quizResultsSubheading1alt}
          </StyledQuizHeading>
          <StyledQuizHeading
            colorType='dark'
            fontSize='xlarge'
            fontWeight='bold'
            marginBottom='small'
            centered={true}
          >
            {`$${format()(savingsLower)} - $${format()(savingsUpper)}`}
          </StyledQuizHeading>
        </div>
      )}

      {savingsLower === savingsUpper && (
        <div>
          <StyledQuizHeading colorType='dark' fontSize='large' marginBottom='small'>
            {strings.quizResultsSubheading1alt2}
          </StyledQuizHeading>
          <StyledQuizHeading
            colorType='dark'
            fontSize='xlarge'
            fontWeight='bold'
            marginBottom='small'
            centered={true}
          >
            {`$${format()(savingsLower)}`}
          </StyledQuizHeading>
        </div>
      )}

      <StyledQuizHeading colorType='dark' fontSize='large' marginBottom='medium'>
        {strings.quizResultsSubheading2}
      </StyledQuizHeading>
      <StyledQuizSubheading marginBottom='medium'>
        {strings.quizResultsSubheading3}
      </StyledQuizSubheading>

      <Desktop>
        <StyledCalendlyWidget url={calendlyUrl} prefill={{ email: email }} />
      </Desktop>
      <TabletAndMobile>
        <CalendlyContainer>
          <TabletAndMobileStyledCalendlyWidget url={calendlyUrl} prefill={{ email: email }} />
        </CalendlyContainer>
      </TabletAndMobile>

      {/* <CalendlyButton /> */}
      {scheduled && (
        <ButtonContainer>
          <HummButton>{strings.buttonContinue}</HummButton>
        </ButtonContainer>
      )}
    </QuizContainer>
  );
}

export default QuizResults;
