import { useState } from 'react';
import styled from 'styled-components';
import { QuizLayout, QuizSubheading } from './QuizLayout';
import HummSelect from '../HummSelect';
import * as strings from '../../utils/strings';

const Spacer = styled.div`
  height: ${(props) => props.theme.layout.margin.large};
`;

const getDefaultIncome = (id, responses, options) => {
  if (responses[`${id}Income`]) {
    return options[responses[`${id}IncomeMetadata`]];
  } else {
    return '';
  }
};

const getDefaultExpenses = (id, responses, options) => {
  if (responses[`${id}Expenses`]) {
    return options[responses[`${id}ExpensesMetadata`]];
  } else {
    return '';
  }
};

function QuizThisYear({ id, stepNum, totalSteps, onChange, onSubmit, onBack, responses }) {
  const [defaultIncome, setDefaultIncome] = useState(
    getDefaultIncome(id, responses, strings.incomeOptions)
  );
  const [defaultExpenses, setDefaultExpenses] = useState(
    getDefaultExpenses(id, responses, strings.expenseOptions)
  );
  const [incomeSet, setIncomeSet] = useState(false);
  const [expensesSet, setExpensesSet] = useState(false);

  const handleIncomeChange = (selectedOption) => {
    setIncomeSet(true);
    onChange(`${id}Income`, {
      target: {
        value: selectedOption.value,
        metadata: strings.incomeOptions.indexOf(selectedOption),
      },
    });
  };

  const handleExpensesChange = (selectedOption) => {
    setExpensesSet(true);
    onChange(`${id}Expenses`, {
      target: {
        value: selectedOption.value,
        metadata: strings.expenseOptions.indexOf(selectedOption),
      },
    });
  };

  return (
    <QuizLayout
      stepNum={stepNum}
      totalSteps={totalSteps}
      heading={strings.quiz5Heading}
      buttonText={strings.buttonSubmit}
      onSubmit={onSubmit}
      onBack={onBack}
      disabled={!(incomeSet && expensesSet)}
    >
      <QuizSubheading marginBottom='small'>{strings.quiz5Question1}</QuizSubheading>
      <HummSelect
        options={strings.incomeOptions}
        onChange={handleIncomeChange}
        defaultValue={defaultIncome}
      />
      <Spacer />
      <QuizSubheading marginBottom='small'>{strings.quiz5Question2}</QuizSubheading>
      <HummSelect
        options={strings.expenseOptions}
        onChange={handleExpensesChange}
        defaultValue={defaultExpenses}
      />
      <Spacer />
    </QuizLayout>
  );
}

export default QuizThisYear;
