import styled from 'styled-components';
import { Subheading, Paragraph } from './text';
import * as strings from '../utils/strings';

const Container = styled.div``;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

function HummPrice({ price, desc }) {
  return (
    <Container>
      <PriceContainer>
        <Subheading fontSize='xlarge' colorType='light' marginBottom='none'>
          {price}
        </Subheading>
        <Paragraph colorType='light' marginBottom='xsmall'>
          {strings.pricingPerMonth}
        </Paragraph>
      </PriceContainer>
      <Paragraph colorType='light'>{desc}</Paragraph>
    </Container>
  );
}

export default HummPrice;
