import { useState } from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import HummDollarValue from './HummDollarValue';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`;

const StyledThumb = styled.div`
  height: 20px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.dark};
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  outline: none;
  transform: translateY(-7px);
`;

const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 1 ? props.theme.colors.lightGray : props.theme.colors.primary};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

function HummSlider({ id, title, min, max, defaultValue = 0, onChange }) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (value) => {
    setValue(value);
    onChange(id, value);
  };

  return (
    <Container>
      <HummDollarValue title={title} value={value} />
      <StyledSlider
        min={min}
        max={max}
        defaultValue={defaultValue}
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={handleChange}
      />
    </Container>
  );
}

export default HummSlider;
