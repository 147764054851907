import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Desktop, TabletAndMobile } from '../../containers/Breakpoints';
import { Heading, Paragraph, Subheading } from '../text';
import { HummButton, ArrowButton } from '../buttons';
import textLogo from '../../assets/HUMM-_PrimaryLogo-Green2.png';
import * as strings from '../../utils/strings';

const Container = styled.div`
  padding: 60px 180px 180px 180px;
`;

const TabletAndMobileContainer = styled.div`
  padding: 20px 20px 20px 20px;
`;

const ContentContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const TabletAndMobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const NavLogo = styled.img`
  height: 60px;
  margin-bottom: 50px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const StyledHeading = styled(Heading)`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const StyledSubheading = styled(Subheading)`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
`;

const StyledThankYou = styled(Paragraph)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: auto;
`;

const StepContainer = styled.div`
  margin-left: auto;
`;

export const QuizContainer = ({ onSubmit, children }) => (
  <form onSubmit={onSubmit}>
    <Desktop>
      <Container>
        <Link to='/'>
          <NavLogo src={textLogo} />
        </Link>
        <ContentContainer>{children}</ContentContainer>
      </Container>
    </Desktop>
    <TabletAndMobile>
      <TabletAndMobileContainer>
        <Link to='/'>
          <NavLogo src={textLogo} />
        </Link>
        <TabletAndMobileContentContainer>{children}</TabletAndMobileContentContainer>
      </TabletAndMobileContainer>
    </TabletAndMobile>
  </form>
);

export const QuizStepCounter = ({ stepNum, totalSteps }) => (
  <StepContainer>
    <Paragraph
      colorType='primary'
      fontSize='xsmall'
      fontWeight='bold'
      marginBottom='xsmall'
    >{`${strings.quizStep} ${stepNum} / ${totalSteps}`}</Paragraph>
  </StepContainer>
);

export const QuizHeading = ({
  className,
  children,
  fontSize,
  fontWeight,
  marginBottom,
  centered,
}) => (
  <StyledHeading
    className={className}
    fontSize={fontSize ? fontSize : 'xlarge'}
    fontWeight={fontWeight}
    colorType='dark'
    marginBottom={marginBottom}
    centered={centered ? centered : false}
  >
    {children}
  </StyledHeading>
);

export const QuizSubheading = ({
  className,
  children,
  fontSize,
  colorType,
  marginBottom,
  centered,
}) => (
  <StyledSubheading
    className={className}
    fontSize={fontSize ? fontSize : 'large'}
    colorType={colorType ? colorType : 'primary'}
    marginBottom={marginBottom}
    centered={centered ? centered : false}
  >
    {children}
  </StyledSubheading>
);

export const QuizSmallThankYou = () => (
  <StyledThankYou colorType='primary' fontSize='xsmall' fontWeight='bold' marginBottom='small'>
    {strings.quizThankYou}
  </StyledThankYou>
);

export const QuizLayout = ({
  stepNum,
  totalSteps,
  heading,
  buttonText,
  children,
  onSubmit,
  onBack,
  showSteps = true,
  centered = false,
  disabled = false,
}) => (
  <QuizContainer onSubmit={onSubmit}>
    {showSteps && (
      <HeaderContainer>
        <ArrowButton onClick={onBack} />
        <QuizStepCounter stepNum={stepNum} totalSteps={totalSteps} />
      </HeaderContainer>
    )}
    <QuizHeading centered={centered}>{heading}</QuizHeading>
    {children}
    <ButtonContainer>
      <HummButton type='submit' value='submit' disabled={disabled}>
        {buttonText}
      </HummButton>
    </ButtonContainer>
  </QuizContainer>
);
