import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HummButton from './HummButton';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

function HummLinkButton({ className, to, light, children }) {
  return (
    <StyledLink to={to}>
      <HummButton className={className} light={light}>
        {children}
      </HummButton>
    </StyledLink>
  );
}

export default HummLinkButton;
